import React from 'react';

interface CustomIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const HubIcon: React.FC<CustomIconProps> = ({
  width = 24,
  height = 24,
  color,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 12C4 7.75736 4 5.63604 5.17157 4.31802C6.34315 3 8.22876 3 12 3C15.7712 3 17.6569 3 18.8284 4.31802C20 5.63604 20 7.75736 20 12C20 16.2426 20 18.364 18.8284 19.682C17.6569 21 15.7712 21 12 21C8.22876 21 6.34315 21 5.17157 19.682C4 18.364 4 16.2426 4 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.75C10.7574 7.75 9.75 8.75736 9.75 10C9.75 11.2426 10.7574 12.25 12 12.25C13.2426 12.25 14.25 11.2426 14.25 10C14.25 8.75736 13.2426 7.75 12 7.75ZM8.25 10C8.25 7.92893 9.92893 6.25 12 6.25C14.0711 6.25 15.75 7.92893 15.75 10C15.75 12.0711 14.0711 13.75 12 13.75C9.92893 13.75 8.25 12.0711 8.25 10Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 17C7.25 16.5858 7.58579 16.25 8 16.25H16C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default HubIcon;
