import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import NewModal from '../NewModal';
import SelectFormField from '../../Shared/SelectFormField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  getAllCaregivers,
  inviteFacilityAdmin,
  resetSuccessStates,
  updateCaregiverById,
} from '../../../store/facility/actionCreators';
import InviteNewAdminForm from './InviteNewAdminForm';
import { AccountDelModalTypes, FACILITY_ROLE } from '../../../shared/constants';
import { ReactComponent as AssignUser } from '../../../images/icons/assign_user.svg';
import { dimGrey } from '../../../mainTheme';
import ProgressBar from '../ProgressBar';
import LoadingSkeleton from './components/LoadingSkeleton ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

const assignAdminSchema = yup.object({
  user: yup.string().required('User is required'),
});

const inviteNewAdminSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
});

interface AssignAdminModalProps {
  onClose: () => void;
  onNext: () => void;
  currentModal: string;
  setCurrentModal: (modal: string | null) => void;
}

const AssignAdminModal: React.FC<AssignAdminModalProps> = ({
  onClose,
  onNext,
  currentModal,
  setCurrentModal,
}) => {
  const {
    dashboardUserInfo,
    caregivers,
    isLoading,
    inviteFacilityAdminSuccess,
    updateCaregiverSuccess,
  } = useSelector((state: RootState) => {
    const { dashboardUser, facility } = state;
    const { dashboardUserInfo } = dashboardUser;
    const { caregivers, inviteFacilityAdminSuccess, updateCaregiverSuccess } =
      facility;
    return {
      dashboardUserInfo,
      caregivers,
      isLoading: facility.isLoading,
      inviteFacilityAdminSuccess,
      updateCaregiverSuccess,
    };
  });

  const dispatch = useDispatch();

  const initialValues = { user: '', firstName: '', email: '' };

  useEffect(() => {
    if (
      dashboardUserInfo &&
      dashboardUserInfo.facility_id &&
      !caregivers.length
    ) {
      dispatch(getAllCaregivers(dashboardUserInfo.facility_id, false));
    }
  }, [caregivers.length, dashboardUserInfo, dispatch]);

  // Filter out caregivers who are already facility admins and map the remaining caregivers to userData array
  interface UserData {
    label: string;
    value: number;
    icon?: JSX.Element;
  }

  const userData: UserData[] = caregivers
    .filter(
      (caregiver) => caregiver.facility_role_id !== FACILITY_ROLE.FACILITY_ADMIN
    )
    .map((caregiver) => ({
      label: `${caregiver.first_name} ${caregiver.last_name}`,
      value: caregiver.customer_id,
    }));

  // Add an option to invite a new user
  userData.push({
    label: 'Invite new user',
    value: 0,
    icon: <FontAwesomeIcon icon={faPlus} />,
  });

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (field: string, value: any) => void,
    resetForm: FormikHelpers<any>['resetForm']
  ) => {
    const value = event.target.value;
    setFieldValue('user', value);
    if (Number(value) === 0) {
      setCurrentModal(AccountDelModalTypes.INVITE_NEW_ADMIN_MODAL);
      resetForm({ values: { user: value, firstName: '', email: '' } });
    } else {
      setCurrentModal(AccountDelModalTypes.ASSIGN_ADMIN_MODAL);
    }
  };

  const handleInviteNewAdminNext = (email: string) => {
    const payload = {
      email,
      facility_role_id: FACILITY_ROLE.FACILITY_ADMIN,
    };
    dispatch(inviteFacilityAdmin(payload));
  };

  const handleFormSubmit = (values: any) => {
    if (currentModal === AccountDelModalTypes.INVITE_NEW_ADMIN_MODAL) {
      handleInviteNewAdminNext(values.email);
    } else {
      const selectedCaregiver = caregivers.find(
        (caregiver) => caregiver.customer_id === values.user
      );
      if (
        selectedCaregiver &&
        selectedCaregiver.facility_role_id !== FACILITY_ROLE.FACILITY_ADMIN
      ) {
        dispatch(
          updateCaregiverById(values.user, {
            facility_role_id: FACILITY_ROLE.FACILITY_ADMIN,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (inviteFacilityAdminSuccess || updateCaregiverSuccess) {
      onNext();
      dispatch(resetSuccessStates());
    }
  }, [inviteFacilityAdminSuccess, updateCaregiverSuccess, onNext, dispatch]);

  return (
    <NewModal onClose={onClose} title="Delete account" showCloseX>
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <Box display="flex" flexDirection="column" gap={4}>
          <ProgressBar currentStep={1} totalSteps={2} />
          <AssignUser />
          <Box>
            <Typography mb={2}>Assign admin</Typography>
            <Typography sx={{ color: dimGrey }}>
              Select the new admin for {''}
              <Typography component="span" fontWeight="bold">
                {dashboardUserInfo?.facility_name}
              </Typography>
              , it&apos;s recommended that you assign a new admin before
              deleting your account.
            </Typography>
          </Box>
          <Formik
            validationSchema={
              currentModal === AccountDelModalTypes.INVITE_NEW_ADMIN_MODAL
                ? inviteNewAdminSchema
                : assignAdminSchema
            }
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
          >
            {({ handleSubmit, setFieldValue, resetForm, isValid, dirty }) => (
              <Form onSubmit={handleSubmit}>
                <Box mb={4}>
                  <Field
                    label="Assign admin role"
                    name="user"
                    placeholder="Select user"
                    component={SelectFormField}
                    options={userData}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      handleSelectChange(event, setFieldValue, resetForm)
                    }
                    useNewStyles
                  />
                </Box>

                {currentModal ===
                  AccountDelModalTypes.INVITE_NEW_ADMIN_MODAL && (
                  <Box mb={4}>
                    <InviteNewAdminForm />
                  </Box>
                )}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  gap={3}
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={onClose}
                    fullWidth
                  >
                    Cancel deletion
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isLoading || !dirty || !isValid}
                  >
                    Next
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </NewModal>
  );
};

export default AssignAdminModal;
