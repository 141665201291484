import React from 'react';
import { Box, Skeleton } from '@mui/material';

const LoadingSkeleton: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="text" height={40} />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" height={118} />
      <Skeleton variant="text" height={80} />
    </Box>
  );
};

export default LoadingSkeleton;
