/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMedEventDetailItem } from '../models/GetMedEventDetailItem';
import type { MedEventType } from '../models/MedEventType';
import type { MedicationDoseEventType } from '../models/MedicationDoseEventType';
import type { MedicationDoseStatusType } from '../models/MedicationDoseStatusType';
import type { UpdateMedEvents } from '../models/UpdateMedEvents';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OwnersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Add new owner
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public addNewOwner(requestBody?: {
    first_name?: string;
    last_name?: string;
    phone?: string;
    gender_id?: number;
    date_of_birth?: string;
    email?: string;
    'address1?'?: string;
    'city?'?: string;
    'state?'?: string;
    'zipcode?'?: string;
    'med_window_time_sec?'?: number;
    'device_id?'?: number;
  }): CancelablePromise<{
    new_owner_id?: number;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/owners/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * List all owners associated with logged in user
   * @param relationshipToOwner Filters results by the calling user's role in relationship to the owner.
   * @param soleRelationship Filters results to include only the calling user's roles for which they are the only user with the owner. NOTE, this only works with family_admin, family_caregiver and family_contact relationships.
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public getOwners(
    relationshipToOwner?:
      | 'family_admin'
      | 'family_caregiver'
      | 'family_contact'
      | 'professional_caregiver'
      | 'facility_caregiver'
      | 'facility_admin',
    soleRelationship?: boolean
  ): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/',
      query: {
        relationship_to_owner: relationshipToOwner,
        sole_relationship: soleRelationship,
      },
      errors: {
        400: `Invalid Parameter`,
        401: `UNAUTHORIZED`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Edit owner (Permission -> Facility Admin and Family Admin careteam)
   * @param ownerId
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public editOwner(
    ownerId: number,
    requestBody?: {
      first_name?: string;
      last_name?: string;
      phone?: string;
      address1?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      gender_id?: number;
      date_of_birth?: string;
      med_window_time_sec?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/owners/{owner_id}',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns device details by ownerID
   * @param ownerId
   * @returns any Success
   * @throws ApiError
   */
  public getDeviceByOwnerId(ownerId?: number): CancelablePromise<{
    id?: number;
    serial_number?: string;
    hardware_version?: string;
    software_version?: string;
    software_version_freeze?: boolean;
    software_channel_id?: number;
    customer_id?: number;
    created_at?: string;
    time_zone?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/device',
      path: {
        owner_id: ownerId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Import a Medication Roll
   * @param ownerId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public createMedicationRoll(
    ownerId?: number,
    requestBody?: {
      batch_id: number;
      start_date: string;
      pharmacy_id: number;
      schedule_template: Array<{
        prescribed_time_of_day_24: string;
        /**
         * Defaults to prescribed time of day if not set
         */
        desired_time_of_day_24: string;
        event_type?: MedEventType;
        pouches: Array<{
          pouch_number: number;
          drugs: Array<{
            national_drug_code: string;
            roll_packaged: boolean;
            is_prn?: boolean;
          }>;
        }>;
      }>;
      schedule_template_cadence?: 'REPEAT_DAYS';
      schedule_template_duration?: number;
    }
  ): CancelablePromise<{
    id?: number;
    serial_number?: string;
    hardware_version?: string;
    software_version?: string;
    software_version_freeze?: boolean;
    software_channel_id?: number;
    customer_id?: number;
    created_at?: string;
    time_zone?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/owners/{owner_id}/medication_rolls',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns medication details by customer Id
   * @param ownerId
   * @param startDate Start date for medication schedule
   * @param endDate End date for medication schedule
   * @param includeInvalidated Flag to determine if to include doses with invalidated statuses
   * @returns any Success
   * @throws ApiError
   */
  public getMedScheduleByOwnerId(
    ownerId: number,
    startDate: any,
    endDate: any,
    includeInvalidated?: boolean
  ): CancelablePromise<{
    /**
     * Customer id of the owner
     */
    customer_id: number;
    /**
     * This is the timezone of the medication schedule's prescribed and desired datetimes, which is currently calculated by the configured timezone in the owner's hub.
     */
    schedule_local_timezone: string;
    schedule_received_at: string;
    counts?: {
      /**
       * Total number of missed med event count
       */
      past_med_event?: number;
      /**
       * Total med events in the schedule
       */
      total_med_event?: number;
      /**
       * Total number of missed pouch/pouches count
       */
      past_due_pouch?: number;
      /**
       * Total number med events that are in the future
       */
      upcoming_med_event?: number;
      /**
       * Total number of dose or tablets
       */
      total_dose?: number;
      /**
       * Total number of dose that are in the future
       */
      upcoming_dose?: number;
      /**
       * Total number of dose that were missed
       */
      past_due_dose?: number;
      /**
       * Total number of dose that were skipped
       */
      skipped_dose?: number;
      /**
       * Total number of dose that were taken
       */
      administered_dose?: number;
      /**
       * Total number of dose that were dispensed using on-the-go feature
       */
      on_the_go_event_dose?: number;
      /**
       * Total number of pouch that were skipped
       */
      skipped_pouch?: number;
      /**
       * Total number of pouch that were taken
       */
      administered_pouch?: number;
      /**
       * Total number of pouch that were dispensed using on-the-go feature
       */
      on_the_go_event_pouch?: number;
      /**
       * Total number of pouch that were caregiver assisted
       */
      caregiver_assist_event_pouch?: number;
      /**
       * Total number of pouch that were self assisted
       */
      smart_assist_event_pouch?: number;
    };
    /**
     * List of objects describing the medication rolls present in the schedule.
     */
    med_rolls: Array<{
      batch_id: number;
      /**
       * First pouch number in the med roll
       */
      starting_pouch_number: number;
      /**
       * Last pouch number in the med roll
       */
      end_pouch_number: number;
      /**
       * Total number of pouches in the med roll
       */
      number_of_pouches: number;
      start_datetime: string;
      end_datetime: string;
    }>;
    schedule: Array<{
      /**
       * treat this as an arbitray string and do not store locally for later reuse, the response value is subject to change in the future
       */
      med_event_id: string;
      /**
       * When the pharmacy prescribed the medication to be taken - immutable
       */
      prescribed_datetime: string;
      /**
       * The time the owner has configured omcare to take their medication, within an acceptable range of the prescribed_datetime
       */
      desired_datetime: string;
      /**
       * The 24 hour clock time of day the owner is allowed to take their medication, in the owners local timezone
       */
      prescribed_time_of_day_24: string;
      /**
       * The 24 hour clock time of day the owner has configured omcare to take their medication, in the owners local timezone
       */
      desired_time_of_day_24: string;
      /**
       * The catergorization of time based on morning, afternoon and evening
       */
      part_of_day?: 'Morning' | 'Afternoon' | 'Evening';
      /**
       * Number of doses - usually 1-3 doses per pouch
       */
      dose_count: number;
      /**
       * Number of pouches - usually 1-3 pouches per med event
       */
      pouch_count: number;
      event_type: MedEventType;
      /**
       * Name of the assigned caregiver for given owner.
       */
      caregiver_name?: string;
      /**
       * Customer id of assigned caregiver for given owner.
       */
      caregiver_customer_id?: number;
      /**
       * Single pouch of medication. Usually one pouch has one or multiple doses.
       */
      pouches: Array<{
        pouch_number: number;
        batch_id: number;
        pouch_id: number;
        dose_count: number;
        /**
         * An object providing count statuses by medication doses status, e.g. Loaded, Missed, Dispensed, Administered
         */
        status_counts: {
          loaded?: number;
          dispensed?: number;
          prescribed?: number;
          dispense_failed?: number;
          bag_removed?: number;
          skipped?: number;
          administered?: number;
          invalidated?: number;
          missed?: number;
          pre_dispensed?: number;
          adherent_self_reported?: number;
          non_adherent_self_reported?: number;
        };
        /**
         * Information about each dose within a pouch
         */
        doses: Array<{
          dose_id: number;
          national_drug_code: string;
          modified_at?: string;
          drug_name: string;
          tablet_count?: number;
          dispensed_at?: string;
          medication_dose_status_id?: number;
          administered_at?: string;
          administered_by?: number;
          administered_timezone?: string;
          received_at?: string;
          status: string;
          last_received_event?: MedicationDoseStatusType;
          roll_packaged?: boolean;
          roll_id?: string;
          is_prn?: boolean;
        }>;
      }>;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/medication_schedule',
      path: {
        owner_id: ownerId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        include_invalidated: includeInvalidated,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update medication details by customer Id
   * @param ownerId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateMedScheduleByOwnerId(
    ownerId: number,
    requestBody?: {
      med_event_id?: string;
      /**
       * only date and time should be passed.
       */
      desired_admin_time?: string;
      event_type?: 'CAREGIVER_ASSIST' | 'SMART_ASSIST';
      /**
       * this field is required only when event type is CAREGIVER_ASSIST
       */
      'caregiver_customer_id?'?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/owners/{owner_id}/medication_schedule',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns med schedule by med event id
   * @param ownerId
   * @param medicationEventId
   * @returns GetMedEventDetailItem Success
   * @throws ApiError
   */
  public getMedScheduleByMedEventId(
    ownerId: number,
    medicationEventId: string
  ): CancelablePromise<GetMedEventDetailItem> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/medication_events/{medication_event_id}',
      path: {
        owner_id: ownerId,
        medication_event_id: medicationEventId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Bulk updates one to many med events sharing the same settings for an owner
   * @param ownerId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateMedEvents(
    ownerId: number,
    requestBody?: UpdateMedEvents
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/owners/{owner_id}/medication_events',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns current status of med events of owner and if calling user has appropriate access
   * @param ownerId
   * @returns any Success
   * @throws ApiError
   */
  public getMedEventActiveStatus(ownerId: number): CancelablePromise<{
    can_start_assist?: boolean;
    'error?'?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/medication_events/is_caregiver_assist_ready',
      path: {
        owner_id: ownerId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Adds a user to the careteam of an owner
   * @param ownerId The unique ID of the owner
   * @param requestBody The careteam member detail
   * @returns void
   * @throws ApiError
   */
  public postOwnersCareteam(
    ownerId: number,
    requestBody?: {
      email: string;
      first_name: string;
      last_name: string;
      password: string;
      /**
       * The careteam_role_id of the new careteam member (2->family admin, 3->family caregiver or 4->family contact)
       */
      customer_careteam_role: number;
    }
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/owners/{owner_id}/careteam',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid params / Already a careteam member`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Returns all the contacts of owner
   * @param ownerId
   * @param caregiverAssistPermission This is a boolean value and if passed true returns contacts eligible to do caregiver assist for given owner
   * @returns any Success
   * @throws ApiError
   */
  public getOwnerContacts(
    ownerId: number,
    caregiverAssistPermission?: boolean
  ): CancelablePromise<
    Array<{
      customer_id?: number;
      first_name?: string;
      last_name?: string;
      email?: string;
      'invite_status_id?'?: number;
      status?: string;
      careteam_role_id?: number;
      careteam_role?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/contacts',
      path: {
        owner_id: ownerId,
      },
      query: {
        caregiver_assist_permission: caregiverAssistPermission,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update the contacts of owner
   * @param ownerId the id of owner on behalf of whom we are making change
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateOwnerContacts(
    ownerId: number,
    requestBody?: {
      customer_id?: number;
      status?: string;
      email?: string;
      careteam_role_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/owners/{owner_id}/contacts',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * delete owner contact
   * @param ownerId the id of owner on behalf of whom we are making change
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public deleteOwnerContacts(
    ownerId: number,
    requestBody?: {
      'customer_id?'?: number;
      status?: string;
      email?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/owners/{owner_id}/contacts',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get permission details of logged in user for given owner
   * @param ownerId customer id of owner for whom user is fetching permission
   * @returns string Success
   * @throws ApiError
   */
  public getPermission(
    ownerId: number
  ): CancelablePromise<
    Array<
      | 'owner.basic.view'
      | 'owner.basic.edit'
      | 'owner.health.view'
      | 'owner.health.edit'
      | 'owner.contacts.view'
    >
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/permissions',
      path: {
        owner_id: ownerId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns the health profile information for the owner
   * @param ownerId
   * @param startDate Start date for health profile
   * @param endDate End date for health profile
   * @returns any Success
   * @throws ApiError
   */
  public getOwnerHealthProfile(
    ownerId: number,
    startDate?: any,
    endDate?: any
  ): CancelablePromise<{
    /**
     * The total number of medications available
     */
    total: number;
    /**
     * The number of medications taken
     */
    taken: number;
    /**
     * The number of medications intentionally not taken
     */
    skipped: number;
    /**
     * The number of medications unintentionally not taken
     */
    missed: number;
    /**
     * The number of medications prescribed in the future
     */
    upcoming: number;
    data?: Array<{
      /**
       * name of the drug
       */
      name?: string;
      total?: number;
      taken?: number;
      skipped?: number;
      missed?: number;
      upcoming?: number;
    }>;
    /**
     * Providing the same numbers by each prescribed medication
     */
    by_medication: any;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/health_profile',
      path: {
        owner_id: ownerId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns the health insights for the owner, based on last 7 days
   * @param ownerId
   * @returns any Success
   * @throws ApiError
   */
  public getOwnerHealthInsights(ownerId: number): CancelablePromise<
    Array<{
      /**
       * The informational text highlighting the insights
       */
      title: string;
      /**
       * An indicator if the insights are positive (good), negative (bad) or a warning (medium).
       */
      sentinment: 'POSITIVE' | 'WARNING' | 'NEGATIVE';
      call_to_action: 'QUICK_CALL';
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/health_insights',
      path: {
        owner_id: ownerId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Adds medication dose events for the owner
   * @param ownerId
   * @param eventName
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public addMedicationDoseEvents(
    ownerId: number,
    eventName: MedicationDoseEventType,
    requestBody?: {
      /**
       * The timestamp of the event.
       */
      timestamp?: string;
      /**
       * An array of doses.
       */
      doses?: Array<{
        /**
         * The ID of the dose.
         */
        dose_id?: string;
        /**
         * The ID of the medication event.
         */
        med_event_id?: string;
        /**
         * The flow of dispensing, this is only required when event_name is dispensed
         */
        dispensed_flow?: 'past_due' | 'on_the_go' | 'med_assist';
        /**
         * The reason for skipping a med dose, this is only required when event_name is skipped
         */
        skipped_reasons?:
          | 'i_forgot'
          | 'i_do_not_understood_directions'
          | 'too_many_medications'
          | 'side_effects'
          | 'medication_not_working'
          | 'doctor_adviced_not_to_take'
          | 'reason_not_listed';
      }>;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/owners/{owner_id}/medication_doses/events/{event_name}',
      path: {
        owner_id: ownerId,
        event_name: eventName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Delete medication doses based on provided filters
   * @param ownerId The unique identifier of the owner
   * @param requestBody
   * @returns any Medication events deleted successfully
   * @throws ApiError
   */
  public deleteOwnersMedicationDoses(
    ownerId: number,
    requestBody: {
      /**
       * Filter conditions to determine which medication events to delete
       */
      filters: {
        /**
         * Array of batch IDs to be deleted
         */
        batch_ids?: Array<string>;
        /**
         * Array of pouch numbers to target specific medication events
         */
        pouch_numbers?: Array<number>;
        /**
         * Array of dose IDs to specify which doses to delete
         */
        dose_ids?: Array<number>;
        /**
         * Array of medication event IDs for deletion in the 'YYYY-MM-DD HH:mm:ss' format
         */
        med_event_ids?: Array<string>;
      };
      /**
       * A flag indicating whether only medication event history should be deleted
       */
      delete_history_only?: boolean;
    }
  ): CancelablePromise<{
    message?: string;
  }> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/owners/{owner_id}/medication_doses',
      path: {
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Returns dose level history of medication event
   * Returns dose level history of medication event
   *
   * @param ownerId
   * @param medicationEventId
   * @returns any Success
   * @throws ApiError
   */
  public getMedEventHistoryByOwnerIdAndMedEventId(
    ownerId: number,
    medicationEventId: string
  ): CancelablePromise<
    Array<{
      /**
       * The prescribed datetime of the medication event
       */
      med_event_id?: string;
      /**
       * Status ID of the medication dose
       */
      status_id?: number;
      /**
       * The timestamp when the medication dose status change occurred
       */
      occured_at?: string;
      /**
       * The timezone of the owners hub
       */
      device_time_zone?: string;
      event_data?: {
        dispensed_flow?: string;
        'skipped_reasons?'?: Array<string>;
      };
      sent_by?: {
        /**
         * The ID of the customer
         */
        customer_id?: number;
        /**
         * The first name of the customer
         */
        customer_first_name?: string;
        /**
         * The last name of the customer
         */
        customer_last_name?: string;
      };
      /**
       * Array of medication doses associated with the same occurrence time
       */
      doses?: Array<{
        /**
         * The ID of the medication dose
         */
        id?: number;
        /**
         * The national drug code for the medication
         */
        national_drug_code?: string;
        /**
         * The name of the medication
         */
        drug_name?: string;
      }>;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/owners/{owner_id}/med_event/{medication_event_id}/history',
      path: {
        owner_id: ownerId,
        medication_event_id: medicationEventId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
