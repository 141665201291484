/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FacilityService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update Facility Med Window Time **CUSTOMER_CARE, ENGINEERING or FACILITY_ADMIN**
   * @param facilityId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateFacility(
    facilityId?: number,
    requestBody?: {
      med_window_time_sec?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/facility/{facility_id}',
      path: {
        facility_id: facilityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Get Facility info by ID
   * @param facilityId Facility ID
   * @returns any Successful
   * @throws ApiError
   */
  public getFacilityById(facilityId?: number): CancelablePromise<{
    id?: number;
    name?: string;
    med_window_time_sec?: number;
    created_at?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/facility/{facility_id}/info',
      path: {
        facility_id: facilityId,
      },
      errors: {
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * get list of Devices assigned to facility
   * @param facilityId Facility ID
   * @returns any Successfully get devices in a facility
   * @throws ApiError
   */
  public getFacilityDevices(facilityId?: number): CancelablePromise<
    Array<{
      id?: number;
      serial_number?: string;
      owner?: {
        id?: number;
        email?: string;
        first_name?: string;
        last_name?: string;
      } | null;
      synced_by?: {
        id?: number;
        email?: string;
        first_name?: string;
        last_name?: string;
      } | null;
      hardware_version?: string;
      software_version?: string;
      software_channel_id?: number;
      pinned_software_version_id?: number | null;
      pinned_software_version_name?: string | null;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/facility/{facility_id}/devices',
      path: {
        facility_id: facilityId,
      },
      errors: {
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * get list of Customers of facility
   * @param facilityId Facility ID
   * @returns any Successfully get customers in a facility
   * @throws ApiError
   */
  public getFacilityCustomers(facilityId?: number): CancelablePromise<
    Array<{
      customer_id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      gender_id?: number;
      date_of_birth?: string;
      is_patient?: boolean;
      previlege_id?: number;
      med_window_time_sec?: number;
      last_login_at?: string;
      email_verified_at?: string;
      created_at?: string;
      modified_at?: string;
      facility_id?: number;
      facility_role_id?: number;
      device_time_zone?: string;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/facility/{facility_id}/customers',
      path: {
        facility_id: facilityId,
      },
      errors: {
        403: `Forbidden`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * MVP endpoint specifically for ecumen. Should be deleted after MVP
   * @param requestBody The customer payload
   * @returns any Successfully created user
   * @throws ApiError
   */
  public postFacilityCustomer(requestBody?: {
    first_name: string;
    last_name: string;
    username: string;
    facility_role_id: number;
    gender?: number;
    pharmacy?: number;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: number;
    country?: string;
    date_of_birth?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/facility/customer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid parameters`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Return customer's personal information.
   * @param customerId customer ID
   * @returns any Successfully get customer information.
   * @throws ApiError
   */
  public getFacilityCustomerDetail(customerId?: number): CancelablePromise<{
    customer_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    phone2?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    gender_id?: number;
    date_of_birth?: string;
    is_patient?: boolean;
    previlege_id?: number;
    med_window_time_sec?: number;
    last_login_at?: string;
    email_verified_at?: string;
    created_at?: string;
    modified_at?: string;
    facility_id?: number;
    facility_role_id?: number;
    device_time_zone?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/facility/customer/{customer_id}',
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `Invalid  parameters`,
        401: `UNAUTHORIZED`,
        403: `Forbidden`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }
}
