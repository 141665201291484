import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { FieldAttributes, getIn } from 'formik';
import React from 'react';
import StyledSelect from './StyledSelect';

interface SelectFormFieldProps {
  field: { name: string; onBlur(): void; onChange(): void; value: string };
  form: FieldAttributes<any>;
  label: string;
  options: any[];
  extra: { children: any; label: string; margin: string; placeHolder: string };
  useNewStyles?: boolean;
}

function SelectFormField(props: SelectFormFieldProps) {
  const { field, form, label, options, useNewStyles, ...extra } = props;
  const errorText = getIn(form.errors, field.name);

  return (
    <FormControl variant="outlined" fullWidth error={!!errorText}>
      <InputLabel id="select_form_field">{label}</InputLabel>
      <StyledSelect
        label={label}
        {...field}
        {...extra}
        useNewStyles={useNewStyles}
      >
        {options.map((op) => (
          <MenuItem key={op.value} value={op.value}>
            {op.icon && <span style={{ marginRight: 8 }}>{op.icon}</span>}
            {op.label}
          </MenuItem>
        ))}
      </StyledSelect>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}

export default SelectFormField;
