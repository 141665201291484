import React, { useState } from 'react';
import AuthLayout from './AuthLayout';
import LoginHappyCard from './components/LoginHappyCard';
import LoginFormCard from './components/LoginFormCard';

const Login = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  return (
    <AuthLayout showOmcareLogo={showLoginForm}>
      {!showLoginForm ? (
        <LoginHappyCard setShowLoginForm={() => setShowLoginForm(true)} />
      ) : (
        <LoginFormCard />
      )}
    </AuthLayout>
  );
};

export default Login;
