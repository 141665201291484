import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import SelectFormField from '../../Shared/SelectFormField';
import { Box } from '@mui/material';
import { Status } from '../HubIndex';

const schema = yup.object({
  owner_name: yup.string().trim(),
  hub_status: yup.string(),
});

interface HubsFilterFormProps {
  formRef: React.RefObject<
    FormikProps<{ owner_name: string; hub_status: string; facility: string }>
  >;
  onSubmit: (values: any) => void;
  initialValues: { owner_name: string; hub_status: string; facility: string };
}

const HubsFilterForm: React.FC<HubsFilterFormProps> = ({
  formRef,
  onSubmit,
  initialValues,
}) => {
  const HubStatus = useMemo(() => {
    return [
      { label: Status.Online, value: Status.Online },
      { label: Status.Offline, value: Status.Offline },
    ];
  }, []);

  return (
    <Box>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Field
                label="Hub owner name"
                name="owner_name"
                placeholder="First or Last name"
                component={TextInputFormField}
              />
              <Field
                label="Hub status"
                name="hub_status"
                placeholder="Select hub status"
                component={SelectFormField}
                options={HubStatus}
                useNewStyles
              />
              <Field
                label="Facility "
                name="facility"
                placeholder="Facility"
                component={TextInputFormField}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default HubsFilterForm;
