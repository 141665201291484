/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RegisterService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Creates a new user in the system
   * @param requestBody The customer payload
   * @returns any Successfully added/registered the customer
   * @throws ApiError
   */
  public registerCustomer(requestBody?: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    phone: string;
    'gender_id?'?: number;
    'zipcode?'?: number;
    date_of_birth: string;
    'token?'?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid parameters`,
        409: `Already exists`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Verify user's registration/email
   * @param requestBody
   * @returns any Email verified
   * @throws ApiError
   */
  public confirmUserRegistration(requestBody?: {
    /**
     * The cognito verification code.
     */
    token: string;
    email: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/register/verify',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid parameters are given`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Initiates the resending of the user verification email (The user will get an email)
   * @param email Email of the user
   * @returns any Verification token resent
   * @throws ApiError
   */
  public userConfirmationReqEmail(email: any): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/register/verificationReq',
      query: {
        email: email,
      },
      errors: {
        400: `Returns when the parameters are invalid`,
        404: `Email match not found`,
        500: `Invalid server error`,
      },
    });
  }
}
