import React from 'react';
import AssignAdminModal from './AssignAdminModal';
import ConfirmDeletionModal from './ConfirmDeletionModal';
import OnlyAdminWarning from './OnlyAdminWarning';
import { AccountDelModalTypes } from '../../../shared/constants';
import { DashboardUser } from '../../../store/dashboardUser/dashboardUserTypes';

interface DeleteAccountModalsProps {
  currentModal: string;
  handlers: {
    onClose: () => void;
    onNext: () => void;
    onConfirmDelete: () => void;
    setCurrentModal: (modal: string | null) => void;
    onDeleteAccount: () => void;
  };
  dashboardUserInfo: DashboardUser | null;
  isOtherUser?: boolean;
}

const DeleteAccountModals: React.FC<DeleteAccountModalsProps> = ({
  currentModal,
  handlers,
  dashboardUserInfo,
  isOtherUser,
}) => {
  switch (currentModal) {
    case AccountDelModalTypes.ONLY_ADMIN_WARNING:
      return (
        <OnlyAdminWarning
          onClose={handlers.onClose}
          onConfirm={handlers.onConfirmDelete}
          dashboardUserInfo={dashboardUserInfo}
        />
      );
    case AccountDelModalTypes.ASSIGN_ADMIN_MODAL:
    case AccountDelModalTypes.INVITE_NEW_ADMIN_MODAL:
      return (
        <AssignAdminModal
          onClose={handlers.onClose}
          onNext={handlers.onNext}
          currentModal={currentModal}
          setCurrentModal={handlers.setCurrentModal}
        />
      );
    case AccountDelModalTypes.CONFIRM_DELETION_MODAL:
      return (
        <ConfirmDeletionModal
          onClose={handlers.onClose}
          onDeleteAccount={handlers.onDeleteAccount}
          isOtherUser={isOtherUser || false}
        />
      );
    default:
      return null;
  }
};

export default DeleteAccountModals;
