import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const stateSelector = (state: RootState) => {
  const { uiToggle } = state;
  const { useNewUI } = uiToggle;
  return {
    useNewUI,
  };
};

// Component toggle between OldComponent and NewComponent
const UIToggleWrapper = (
  OldComponent: React.ComponentType,
  NewComponent: React.ComponentType
) => {
  // Define the functional component which uses hooks
  const WrappedComponent: React.FC = (props) => {
    const { useNewUI } = useSelector(stateSelector);

    // Conditionally render either the old or the new component based on `useNewUI`
    return useNewUI ? <NewComponent {...props} /> : <OldComponent {...props} />;
  };

  // Set a display name for the component for better debugging
  WrappedComponent.displayName = `UIToggleWrapper(${
    OldComponent.displayName || OldComponent.name
  }, ${NewComponent.displayName || NewComponent.name})`;

  return WrappedComponent;
};

export default UIToggleWrapper;
