import React from 'react';
import { Typography } from '@mui/material';
import NewModal from '../NewModal';
import { DashboardUser } from '../../../store/dashboardUser/dashboardUserTypes';
import { dimGrey } from '../../../mainTheme';

interface OnlyAdminWarningProps {
  onClose: () => void;
  onConfirm: () => void;
  dashboardUserInfo: DashboardUser | null;
}

const OnlyAdminWarning: React.FC<OnlyAdminWarningProps> = ({
  onClose,
  onConfirm,
  dashboardUserInfo,
}) => {
  return (
    <NewModal
      onClose={onClose}
      title="Delete account"
      actions={{
        primaryAction: {
          text: 'Delete account',
          onClick: onConfirm,
        },
        secondaryAction: {
          text: 'Cancel',
          onClick: onClose,
          varient: 'text',
        },
      }}
      showCloseX
    >
      <Typography sx={{ textAlign: 'center', color: dimGrey }}>
        Since you are the only admin for {''}
        <Typography component="span" fontWeight="bold">
          {dashboardUserInfo?.facility_name}
        </Typography>
        , it&apos;s recommended that you assign a new admin before deleting your
        account. To delete and shutdown the account, please contact Ōmcare by
        phone or email.
        <Typography
          my={2}
          sx={{
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '0.36px',
          }}
        >
          1-888-888-8888
          <Typography>support@omcare.com</Typography>
        </Typography>
      </Typography>
    </NewModal>
  );
};

export default OnlyAdminWarning;
