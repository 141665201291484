/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceShadowOptions } from '../models/DeviceShadowOptions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DevicesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns all devices and their details (Access - ENG)
   * @param facilityAssigned Optional boolean value to filter devices assigned (or not) to a facility
   * @param ownerAssigned Optional boolean value to filter devices assigned (or not) to an owner
   * @returns any Success
   * @throws ApiError
   */
  public getDevices(
    facilityAssigned?: boolean,
    ownerAssigned?: boolean
  ): CancelablePromise<
    Array<{
      id?: number;
      serial_number?: string;
      customer_id?: number;
      first_name?: string;
      last_name?: string;
      sync_code_done_by?: number;
      sync_code_done_by_first_name?: string;
      sync_code_done_by_last_name?: string;
      email?: string;
      date_of_birth?: string;
      facility_id?: number;
      facility_name?: string;
      hardware_version?: string;
      software_version?: string;
      software_channel_id?: number;
      pinned_software_version_id?: number;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/devices',
      query: {
        facility_assigned: facilityAssigned,
        owner_assigned: ownerAssigned,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Add a new device  (Access - ENG)
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public addDevice(requestBody?: {
    serial_number?: string;
    hardware_version?: string;
    software_version?: string;
    certificate_serial_number?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/devices',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Unsync hub from user
   * @param deviceId device id of hub to be unsynced
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public unsyncHub(deviceId: number): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/devices/{device_id}/unsync',
      path: {
        device_id: deviceId,
      },
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Link device to owner (Access - ENG,CustomerCare,FacilityAdmin,D2C careteam)
   * @param deviceId
   * @param ownerId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public linkDeviceToOwner(
    deviceId?: number,
    ownerId?: number,
    requestBody?: {
      'force?'?: boolean;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/devices/{device_id}/register/{owner_id}',
      path: {
        device_id: deviceId,
        owner_id: ownerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Unassign device to owner (Access - ENG/CC/Facility Admin/Scheduler)
   * @param deviceId
   * @returns any Success
   * @throws ApiError
   */
  public unassignDeviceToOwner(deviceId?: number): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/devices/{device_id}/unassign',
      path: {
        device_id: deviceId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns device details by deviceID (Access - ENG)
   * @param deviceId
   * @returns any Success
   * @throws ApiError
   */
  public getDeviceById(deviceId?: number): CancelablePromise<{
    id?: number;
    serial_number?: string;
    hardware_version?: string;
    software_version?: string;
    software_version_freeze?: boolean;
    software_channel_id?: number;
    pinned_software_version_id?: number;
    pinned_software_version_name?: string;
    customer_id?: number;
    created_at?: string;
    time_zone?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/devices/{device_id}',
      path: {
        device_id: deviceId,
      },
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Updates a specific device (Access - ENG)
   * @param deviceId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public putDeviceById(
    deviceId?: number,
    requestBody?: {
      software_channel_id?: number;
      pinned_software_version_id?: number;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/devices/{device_id}',
      path: {
        device_id: deviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Returns device shadow (Access - ENG/CUSTOMERCARE)
   * @returns any Success
   * @throws ApiError
   */
  public getDeviceShadow(): CancelablePromise<{
    desired?: DeviceShadowOptions;
    reported?: DeviceShadowOptions;
    delta?: DeviceShadowOptions;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/devices/{device_id}/shadow',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Updates device shadow (Access - ENG/CUSTOMERCARE)
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public updateDeviceShadow(
    requestBody?: DeviceShadowOptions
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/devices/{device_id}/shadow',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * validate sync code
   * @param assign Optional query parameter when we want to do sync and assignment at same time
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public validateSyncCode(
    assign?: boolean,
    requestBody?: {
      sync_code?: string;
      /**
       * Optional field representing id of owner when we want to do sync and assignment at same time
       */
      'patient_customer_id?'?: number;
    }
  ): CancelablePromise<{
    device_id?: number;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/devices/validate_sync_code',
      query: {
        assign: assign,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID SYNC CODE`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
