import { Reducer } from 'redux';

import {
  HubOwnerStore,
  HubOwnerStoreAction,
  HubOwnerStoreActionTypes,
} from './types';

const initialState: HubOwnerStore = {
  importMedicationRollSuccess: false,
  hubOwners: [],
  isLoading: false,
  addHubOwnerSuccess: false,
};

const hubOwnerReducer: Reducer<HubOwnerStore, HubOwnerStoreAction> = (
  state = initialState,
  action
): HubOwnerStore => {
  switch (action.type) {
    case HubOwnerStoreActionTypes.CREATE_MEDICATION_ROLL_SUCCESS: {
      let newState = {
        ...state,
        importMedicationRollSuccess: true,
      };
      return newState;
    }
    case HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE_TEMPLATE: {
      const { medScheduleTemplate } = action.payload;

      let newState = {
        ...state,
        medScheduleTemplate: medScheduleTemplate,
      };
      return newState;
    }
    case HubOwnerStoreActionTypes.RESET_IMPORT_MED_ROLL_FORM: {
      return {
        ...state,
        importMedicationRollSuccess: false,
      };
    }
    case HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE: {
      const { medSchedule } = action.payload;

      return {
        ...state,
        medSchedule: medSchedule,
      };
    }
    case HubOwnerStoreActionTypes.GET_MEDICATION_EVENT_HISTORY: {
      const { medEventHistory, ownerId, medEventId } = action.payload;

      return {
        ...state,
        medEventHistory: {
          ownerId,
          medEventId,
          history: medEventHistory,
        },
      };
    }
    case HubOwnerStoreActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HubOwnerStoreActionTypes.UNSET_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case HubOwnerStoreActionTypes.FETCH_HUB_OWNERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hubOwners: action.payload,
      };
    }
    case HubOwnerStoreActionTypes.ADD_HUB_OWNER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        addHubOwnerSuccess: true,
      };
    }
    case HubOwnerStoreActionTypes.RESET_ADD_HUB_OWNER_SUCCESS: {
      return {
        ...state,
        addHubOwnerSuccess: false,
      };
    }
    case HubOwnerStoreActionTypes.EDIT_OWNER_SUCCESS: {
      const { ownerId, requestBody } = action.payload;
      const { phone, address1 } = requestBody;
      const updatedOwners = state?.hubOwners?.map((owner) =>
        owner.customer_id === ownerId ? { ...owner, phone, address1 } : owner
      );
      return {
        ...state,
        hubOwners: updatedOwners,
      };
    }

    default:
      return state;
  }
};

export default hubOwnerReducer;
