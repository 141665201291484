import React from 'react';
import AuthLayout from './AuthLayout';
import VerifyEmailCard from './components/VerifyEmailCard';

const RegisterEmailVerify = () => {
  return (
    <AuthLayout showOmcareLogo>
      <VerifyEmailCard />
    </AuthLayout>
  );
};

export default RegisterEmailVerify;
