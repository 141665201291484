/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Login to the Cognito Service
   * @param newPasswordChallenge set a new password during initial login
   * @param requestBody The credentials object
   * @returns any Returns valid id, access and refresh tokens
   * @throws ApiError
   */
  public authLogin(
    newPasswordChallenge?: boolean,
    requestBody?: {
      username: string;
      password: string;
      /**
       * only passsed when query parameter new_password_challenge=true
       */
      'new_password?'?: string;
    }
  ): CancelablePromise<{
    access_token?: string;
    id_token?: string;
    refresh_token?: string;
    token_type?: string;
    /**
     * seconds
     */
    expires_in?: number;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/login',
      query: {
        new_password_challenge: newPasswordChallenge,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Login failure`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Logs out from the system
   * @param requestBody Should send the FCM token (if applicable)
   * @returns any Success
   * @throws ApiError
   */
  public oauthLogout(requestBody?: {
    fcm_token?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/logout',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `UNAUTHORIZED`,
        404: `User match not found`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Logs out from all active sessions
   * @returns any Success
   * @throws ApiError
   */
  public oauthGlobalLogout(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/globalLogout',
      errors: {
        401: `UNAUTHORIZED`,
        404: `User match not found`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Changes the password of the user
   * @param requestBody The payload containing _current_ and _new_ password
   * @returns any Success
   * @throws ApiError
   */
  public changeUserPassword(requestBody?: {
    current_password: string;
    new_password: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/changePassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid parameters are given`,
        401: `UNAUTHORIZED`,
        404: `User match not found`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Initiates a forgot password request for the user (The user will get an email)
   * @param email Email of the user who forgot the password
   * @returns any Email sent
   * @throws ApiError
   */
  public forgotPasswordReqEmail(email: any): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/auth/forgetPasswordReq',
      query: {
        email: email,
      },
      errors: {
        400: `Returns when the parameters are invalid`,
        404: `Email match not found`,
        500: `Invalid server error`,
      },
    });
  }

  /**
   * Reset the password for the user (Forgot password flow)
   * @param requestBody Change password request payload
   * @returns any Sucesss
   * @throws ApiError
   */
  public resetUserPassword(requestBody?: {
    /**
     * The reset password cognito verification code.
     */
    token: string;
    email: string;
    new_password: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/resetPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid parameters are given`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Validate the access token
   * @param requestBody Required privileges for permissions check
   * @returns any Success
   * @throws ApiError
   */
  public validateAccessToken(requestBody?: {
    'required_privilege_ids?'?: Array<number>;
  }): CancelablePromise<{
    token?: {
      user?: {
        customer_id?: number;
        email?: string;
        first_name?: string;
        last_name?: string;
        phone?: string;
        phone2?: string;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        country?: string;
        age?: number;
        date_of_birth?: string;
        is_patient?: boolean;
        privilege_id?: number;
        med_window_time_sec?: number;
        last_login_at?: string;
        email_verified_at?: string;
        created_at?: string;
        modified_at?: string;
        deleted_at?: string;
        facility_id?: number;
        facility_role_id?: number;
        pharmacy_name?: string;
        pharmacy_patient_id?: number;
        device_serial_number?: string;
        device_id?: number;
        facility_name?: string;
        pharmacy_id?: number;
        pharmacy_role_id?: number;
        device_maintenance_required?: boolean;
      };
      accessToken?: string;
    };
    user?: {
      customer_id?: number;
      email?: string;
      first_name?: string;
      last_name?: string;
      phone?: string;
      phone2?: string;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zipcode?: string;
      country?: string;
      age?: number;
      date_of_birth?: string;
      is_patient?: boolean;
      privilege_id?: number;
      med_window_time_sec?: number;
      last_login_at?: string;
      email_verified_at?: string;
      created_at?: string;
      modified_at?: string;
      deleted_at?: string;
      facility_id?: number;
      facility_role_id?: number;
      pharmacy_name?: string;
      pharmacy_patient_id?: number;
      device_serial_number?: string;
      device_id?: number;
      facility_name?: string;
      pharmacy_id?: number;
      pharmacy_role_id?: number;
      device_maintenance_required?: boolean;
    };
    type?: 'cognito';
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/auth/validateToken',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `UNAUTHORIZED`,
        404: `User match not found`,
        500: `Internal server error`,
      },
    });
  }

  /**
   * Get a new access token with refresh token
   * @param requestBody The refresh token
   * @returns any Returns valid id, access and refresh tokens
   * @throws ApiError
   */
  public keepAccessTokenAlive(requestBody?: {
    refresh_token?: string;
  }): CancelablePromise<{
    access_token?: string;
    id_token?: string;
    refresh_token?: string;
    token_type?: string;
    /**
     * seconds
     */
    expires_in?: number;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/token/keepAlive',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Invalid refresh token or session expired`,
        404: `User match not found`,
        500: `Internal server error`,
      },
    });
  }
}
