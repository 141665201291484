import { Reducer } from 'redux';
import {
  UIToggleStore,
  UIToggleStoreAction,
  UIToggleActionTypes,
} from './uiToggleTypes';

const initialState: UIToggleStore = {
  useNewUI: JSON.parse(sessionStorage.getItem('useNewUI') || 'false'), // Initialize from session storage
};

const uiToggleReducer: Reducer<UIToggleStore, UIToggleStoreAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UIToggleActionTypes.TOGGLE_UI: {
      const { useNewUI } = action.payload;
      sessionStorage.setItem('useNewUI', JSON.stringify(useNewUI)); // Save to session storage
      return { ...state, useNewUI };
    }
    default:
      return state;
  }
};

export default uiToggleReducer;
