import React from 'react';

interface HomeIconProps {
  color?: string;
  size?: number;
  [key: string]: any;
}

const HomeIcon: React.FC<HomeIconProps> = ({ color, size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C11.2919 1.25 10.6485 1.45282 9.95055 1.79224C9.27585 2.12034 8.49643 2.60409 7.52287 3.20832L7.52286 3.20832L5.45628 4.4909L5.45628 4.4909L5.45626 4.49091C4.53507 5.06262 3.79744 5.5204 3.2289 5.95581C2.64015 6.40669 2.18795 6.86589 1.86131 7.46263C1.53536 8.05812 1.38857 8.69174 1.31819 9.4407C1.24999 10.1665 1.24999 11.0541 1.25 12.1672V12.1672V12.1672V13.7799V13.7799C1.24999 15.6838 1.24998 17.1866 1.4027 18.3616C1.55937 19.567 1.88856 20.5401 2.63236 21.3094C3.37958 22.0824 4.33046 22.4277 5.50761 22.5914C6.64849 22.75 8.10556 22.75 9.94185 22.75H9.9419H14.0581H14.0582C15.8944 22.75 17.3515 22.75 18.4924 22.5914C19.6695 22.4277 20.6204 22.0824 21.3676 21.3094C22.1114 20.5401 22.4406 19.567 22.5973 18.3616C22.75 17.1866 22.75 15.6838 22.75 13.7799V13.7799V13.7798V12.1672V12.1671C22.75 11.0541 22.75 10.1665 22.6818 9.4407C22.6114 8.69174 22.4646 8.05812 22.1387 7.46263C21.8121 6.86589 21.3599 6.40669 20.7711 5.95581C20.2026 5.5204 19.4649 5.06262 18.5437 4.49091L16.4771 3.20831L16.4771 3.20831C15.5036 2.60408 14.7241 2.12034 14.0494 1.79224C13.3515 1.45282 12.7081 1.25 12 1.25ZM9.44666 15.3975C9.1139 15.1509 8.64418 15.2207 8.39752 15.5534C8.15087 15.8862 8.22067 16.3559 8.55344 16.6026C9.52585 17.3234 10.7151 17.75 12 17.75C13.285 17.75 14.4742 17.3234 15.4467 16.6026C15.7794 16.3559 15.8492 15.8862 15.6026 15.5534C15.3559 15.2207 14.8862 15.1509 14.5534 15.3975C13.8251 15.9374 12.9459 16.25 12 16.25C11.0541 16.25 10.175 15.9374 9.44666 15.3975Z"
        fill={color} // Use 'fill' instead of 'color'
      />
    </svg>
  );
};

export default HomeIcon;
