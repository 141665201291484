import React from 'react';
import { Box, styled } from '@mui/system';
import authLogo from '../../../images/omcare_auth_logo.png';
import { Button, Typography } from '@mui/material';

const OmcareLogo = styled('img')({
  height: '92px',
});

interface LoginHappyCardProps {
  setShowLoginForm: () => void;
}

const LoginHappyCard: React.FC<LoginHappyCardProps> = ({
  setShowLoginForm,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <OmcareLogo src={authLogo} alt="OmcareLogo" />
      <Typography>Welcome to Ōmcare!</Typography>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => {
          setShowLoginForm();
        }}
      >
        Log in
      </Button>
    </Box>
  );
};

export default LoginHappyCard;
