import { Box } from '@mui/material';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface ReCaptchaVerifierV2Props {
  sitekey: string;
  onVerify: (verified: boolean) => void;
}

const ReCaptchaVerifierV2: React.FC<ReCaptchaVerifierV2Props> = ({
  sitekey,
  onVerify,
}) => {
  const handleCaptcha = (value: any) => {
    const isVerified = !!value;
    onVerify(isVerified);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" py={2}>
      <ReCAPTCHA sitekey={sitekey} onChange={handleCaptcha} />
    </Box>
  );
};

export default ReCaptchaVerifierV2;
