import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import React from 'react';

interface CustomExpandMoreProps {
  useNewStyles?: boolean;
}

const CustomExpandMore = ({ useNewStyles, ...rest }: CustomExpandMoreProps) => {
  return (
    <FontAwesomeIcon
      icon={faAngleDown}
      size="2x"
      style={useNewStyles ? { width: '20px', height: '20px' } : {}}
      {...rest}
    />
  );
};

interface StyledSelectProps {
  label?: string;
  name?: string;
  children: JSX.Element | JSX.Element[];
  value: any;
  onChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  inputProps?: any;
  sx?: SxProps<Theme> | undefined;
  useNewStyles?: boolean;
}

function StyledSelect(props: StyledSelectProps) {
  const {
    label,
    name,
    value,
    onChange,
    disabled,
    children,
    inputProps,
    sx,
    useNewStyles,
  } = props;

  return (
    <Select
      fullWidth
      labelId="select_form_field"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      inputProps={inputProps}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            maxHeight: '500px',
            boxShadow: useNewStyles ? '0px 1px 4px 0px rgba(0, 0, 0, 0.4)' : '',
            borderRadius: useNewStyles ? '8px' : '',
          },
        },
      }}
      displayEmpty
      IconComponent={(props) => (
        <CustomExpandMore {...props} useNewStyles={useNewStyles} />
      )}
      sx={{
        ...sx,
        '.MuiSelect-icon': useNewStyles
          ? {
              top: '30%',
            }
          : {},
      }}
    >
      {children}
    </Select>
  );
}

export default StyledSelect;
