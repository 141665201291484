import React, { useState, useCallback, useEffect } from 'react';
import { Box, Popover, Typography, Button, IconButton } from '@mui/material';
import { faGear, faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dimGrey, topBarBg } from '../../../mainTheme';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../store/auth/actionCreators';
import { getCookie } from '../../../shared/utils';
import { RootState } from '../../../store';
import { isNull } from 'lodash';
import { getDashboardUser } from '../../../store/dashboardUser/actionCreators';
import { useHistory } from 'react-router-dom';

const Topbar = () => {
  const history = useHistory();

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const dispatch = useDispatch();

  const { dashboardUserInfo } = useSelector(
    (state: RootState) => state.dashboardUser
  );

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const handleSettingsClick = () => {
    history.push('/v2/settings');
  };

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem('useNewUI');
    dispatch(logOut(getCookie('refresh_token') || ''));
  }, [dispatch]);

  const open = Boolean(profileAnchorEl);

  return (
    <Box
      bgcolor={topBarBg}
      height="72px"
      display="flex"
      justifyContent="end"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
    >
      <Box p={2} display="flex">
        <IconButton onClick={handleSettingsClick} style={{ color: dimGrey }}>
          <FontAwesomeIcon icon={faGear} size="sm" />{' '}
        </IconButton>

        <IconButton onClick={handleClickProfile} style={{ color: dimGrey }}>
          <FontAwesomeIcon icon={faCircleUser} size="sm" />
        </IconButton>
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={profileAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            // Commenting out since design is not avalible now .
            // border={`1px solid ${topBarBg}`}
            bgcolor={topBarBg}
          >
            <Typography align="center">
              Hi, {dashboardUserInfo?.first_name}
            </Typography>
            <Button onClick={handleLogout}>Logout</Button>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default Topbar;
