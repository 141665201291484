import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';

interface WarningModalProps {
  message: string;
  open: boolean;
  onClose: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  message,
  open,
  onClose,
}) => {
  const handleOk = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
        p={4}
        borderRadius={2}
        boxShadow={3}
        style={{
          outline: 'none',
          margin: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography variant="h6" gutterBottom>
          {message}
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleOk}>
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default WarningModal;
