import { setCookieDomain, setRestHost } from './shared/utils';

let web_socket_host = process.env.REACT_APP_SOCKET_HOST;
if ('443' !== process.env.REACT_APP_SOCKET_USER_PORT_HTTPS) {
  web_socket_host =
    web_socket_host + ':' + process.env.REACT_APP_SOCKET_USER_PORT_HTTPS;
}

let rest_host = process.env.REACT_APP_REST_HOST;
if ('443' !== process.env.REACT_APP_REST_USER_PORT_HTTPS) {
  rest_host = rest_host + ':' + process.env.REACT_APP_REST_USER_PORT_HTTPS;
}

let storage_host = process.env.REACT_APP_STORAGE_HOST;
if ('443' !== process.env.REACT_APP_STORAGE_USER_PORT_HTTPS) {
  storage_host =
    storage_host + ':' + process.env.REACT_APP_STORAGE_USER_PORT_HTTPS;
}

export const webSocketHost = web_socket_host ? web_socket_host : '';
export const restHost = rest_host ? rest_host : '';
export const storageHost = storage_host ? storage_host : '';

setRestHost(restHost);
setCookieDomain(
  process.env.REACT_APP_COOKIE_DOMAIN ? process.env.REACT_APP_COOKIE_DOMAIN : ''
);
