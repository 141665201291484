import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { topBarBg } from '../../mainTheme';
import OverviewCard from './components/OverviewCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { fetchHubOwners } from '../../store/hubOwner/actionCreators';
import { RELATIONSHIP_TO_OWNER } from '../../shared/constants';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { isNull } from 'lodash';
import {
  getAllCaregivers,
  getFacilityDevices,
} from '../../store/facility/actionCreators';
import { MedStatusEnum } from '../../shared/utils';

const stateSelector = (state: RootState) => {
  const { dashboardUser, hubOwner, facility } = state;
  const { dashboardUserInfo } = dashboardUser;
  const { hubOwners } = hubOwner;
  const { caregivers, facilityDevices } = facility;

  return {
    dashboardUserInfo,
    isLoading:
      dashboardUser.isLoading || hubOwner.isLoading || facility.isLoading,
    hubOwners,
    caregivers,
    facilityDevices,
  };
};

const HomePage = () => {
  const dispatch = useDispatch();
  const {
    dashboardUserInfo,
    hubOwners,
    isLoading,
    caregivers,
    facilityDevices,
  } = useSelector(stateSelector);

  useEffect(() => {
    if (dashboardUserInfo && dashboardUserInfo.facility_id) {
      dispatch(fetchHubOwners(RELATIONSHIP_TO_OWNER.FACILITY_ADMIN));
      dispatch(getAllCaregivers(dashboardUserInfo.facility_id, true));
      if (!facilityDevices) {
        dispatch(getFacilityDevices(dashboardUserInfo.facility_id));
      }
    }
  }, [dashboardUserInfo, facilityDevices, dispatch]);

  const totalOwners = hubOwners?.length || 0;
  const upToDateOwners =
    hubOwners?.filter((owner) => owner.med_status === MedStatusEnum.UpToDate)
      ?.length || 0;
  const adherenceRate =
    totalOwners > 0 ? Math.round((upToDateOwners / totalOwners) * 100) : 0;

  const unassignedHubs = facilityDevices?.devices?.filter(
    (device) => device.owner === null
  );
  const totalUnassignedHubs = unassignedHubs?.length || 0;

  if (isLoading || isNull(dashboardUserInfo)) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Box bgcolor={topBarBg} height="72px" pl={2}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Welcome
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '25px', md: '30px' },
            fontWeight: 700,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {dashboardUserInfo?.facility_name}
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={2} p={2}>
        <OverviewCard
          title="Adherent owners"
          count={`${adherenceRate.toString()}%`}
          description="Over past month"
          link="/v2/owners"
        />
        <OverviewCard
          title="Total users"
          count={caregivers.length}
          link="/v2/caregivers"
        />
        <OverviewCard
          title="Unassigned hubs"
          count={totalUnassignedHubs}
          link="/v2/hubs"
        />
      </Box>
    </Box>
  );
};

export default HomePage;
