import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import NewModal from '../NewModal';
import { ReactComponent as DeleteIcon } from '../../../images/icons/delete_icon.svg';
import { dimGrey, lightBlack } from '../../../mainTheme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

import ProgressBar from '../ProgressBar';
import LoadingSpinner from '../LoadingSpinner';

interface ConfirmDeletionModalProps {
  onClose: () => void;
  onDeleteAccount: () => void;
  isOtherUser: boolean;
}

const ConfirmDeletionModal: React.FC<ConfirmDeletionModalProps> = ({
  onClose,
  onDeleteAccount,
  isOtherUser,
}) => {
  const commonTypographyProps = {
    color: lightBlack,
  };

  const dispatch = useDispatch();

  const { deleteCaregiverSuccess, isLoading } = useSelector(
    (state: RootState) => {
      const { facility } = state;
      const { deleteCaregiverSuccess, isLoading } = facility;
      return {
        deleteCaregiverSuccess,
        isLoading,
      };
    }
  );

  const [confirmationText, setConfirmationText] = useState('');

  const deletionText = isOtherUser
    ? 'this user'
    : 'your profile and other account information';

  const handleDelete = () => {
    if (confirmationText === 'delete') {
      onDeleteAccount();
    }
  };

  useEffect(() => {
    if (deleteCaregiverSuccess) {
      onClose();
    }
  }, [deleteCaregiverSuccess, dispatch, onClose]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <NewModal
      onClose={onClose}
      title={isOtherUser ? 'Delete user' : 'Delete account'}
      showCloseX
    >
      {isOtherUser ? null : <ProgressBar currentStep={2} totalSteps={2} />}
      <Box display="flex" flexDirection="column" gap={3}>
        <Box mt={2}>
          <DeleteIcon />
        </Box>
        <Box>
          <Typography fontSize="22px">Confirm deletion</Typography>
          <Typography sx={{ color: dimGrey }} component="span">
            Confirming means {deletionText} will be{' '}
            <Typography
              component="span"
              fontWeight="bold"
              {...commonTypographyProps}
            >
              permanently deleted.
            </Typography>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={0.5}
        >
          <Typography
            fontSize="18px"
            fontWeight="bold"
            {...commonTypographyProps}
          >
            Type the word &quot;delete&quot; to confirm deletion.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </Box>

        <Box width="100%" display="flex" justifyContent="center" gap={3} px={2}>
          <Button variant="text" color="secondary" fullWidth onClick={onClose}>
            Cancel deletion
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            disabled={confirmationText !== 'delete'}
          >
            {isOtherUser ? 'Delete user' : 'Delete account'}
          </Button>
        </Box>
      </Box>
    </NewModal>
  );
};

export default ConfirmDeletionModal;
