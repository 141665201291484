import React from 'react';
import AuthLayout from './AuthLayout';
import ResetPasswordFormCard from './components/ResetPasswordFormCard';
import CreatePasswordCard from './components/CreatePasswordCard';
import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  return (
    <AuthLayout showOmcareLogo>
      {email ? <CreatePasswordCard /> : <ResetPasswordFormCard />}
    </AuthLayout>
  );
};
export default ResetPassword;
