import { faAngleDown, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/system';
import isNull from 'lodash/isNull';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import navLogo from '../../images/omcarenavlogo.png';
import { lightTeal } from '../../mainTheme';
import { FACILITY_ROLE, NavBarMenus } from '../../shared/constants';
import {
  getCookie,
  getFacilityRoleById,
  getPrivilegeById,
  isRoleEligibleForNewUI,
} from '../../shared/utils';
import { RootState } from '../../store';
import { logOut } from '../../store/auth/actionCreators';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import { NavBarMenu, NavBarOptions } from '../../store/GlobalTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import UIToggleSwitch from '../../shared/UIToggleSwitch/UIToggleSwitch';

const NavLink = styled(Link)(() => ({
  background: 'none',
  border: 'none',
  color: 'inherit',
  padding: 0,
  borderRadius: '0px',
  minWidth: '0px',
  marginRight: '75px',
}));

const CustomizedButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'isCustomComponent' && prop !== 'activePage',
})<{
  isCustomComponent: boolean | undefined;
  activePage: boolean;
}>(({ isCustomComponent, activePage }) => ({
  background: 'none',
  border: 'none',
  color: activePage ? lightTeal : 'inherit',
  padding: 0,
  borderRadius: '0px',
  minWidth: '0px',
  marginRight: isCustomComponent ? 'inherit' : '75px',
  fontSize: '18px',
}));

const OmcareLogo = styled('img')({
  height: '30px',
});

const CustomPaper = styled(Paper)({ borderRadius: '20px', zIndex: 1991 });

const UsernameWrapper = styled('div')({ marginLeft: '16px', fontWeight: 700 });

const Wrapper = styled('div')({
  flexGrow: 1,
  position: 'relative',
  zIndex: 1241,
});

const stateSelector = (state: RootState) => {
  const {
    auth,
    customers,
    dashboardUser,
    devices,
    facility,
    pharmacy,
    software,
  } = state;
  const { dashboardUserInfo } = dashboardUser;
  return {
    dashboardUserInfo,
    isLoading:
      auth.isLoading ||
      customers.isLoading ||
      dashboardUser.isLoading ||
      devices.isLoading ||
      facility.isLoading ||
      pharmacy.isLoading ||
      software.isLoading,
  };
};

function NavBar() {
  const { dashboardUserInfo, isLoading } = useSelector(stateSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem('useNewUI');
    dispatch(logOut(getCookie('refresh_token') || ''));
  }, [dispatch]);

  const menuOptions = useMemo(() => {
    if (!dashboardUserInfo) {
      return [];
    }
    const privilege = getPrivilegeById(dashboardUserInfo.privilege_id);
    switch (privilege) {
      case 'engineering':
        return [
          NavBarMenus.Users,
          NavBarMenus.Partners,
          NavBarMenus.Devices,
          NavBarMenus.Software,
          NavBarMenus.Reporting,
          NavBarMenus.Operations,
        ];
      case 'customer_care':
        return [
          NavBarMenus.Customers,
          NavBarMenus.Partners,
          NavBarMenus.Devices,
          NavBarMenus.Operations,
        ];
    }
    switch (dashboardUserInfo.facility_role_id) {
      case FACILITY_ROLE.FACILITY_ADMIN:
        return [
          NavBarMenus.Customers,
          NavBarMenus.Reporting,
          NavBarMenus.MyFacility,
        ];
      case FACILITY_ROLE.FACILITY_SCHEDULER:
        return [NavBarMenus.Customers, NavBarMenus.Reporting];
      default:
        return [];
    }
  }, [dashboardUserInfo]);

  const profileMenu = useMemo(() => {
    if (!dashboardUserInfo) {
      return null;
    }
    const profile = { ...NavBarMenus.Profile };

    profile.component = <FontAwesomeIcon icon={faUser} />;

    profile.options[0].component = (
      <UsernameWrapper key={dashboardUserInfo.first_name} tabIndex={-1}>
        Hi, {dashboardUserInfo.first_name}
      </UsernameWrapper>
    );

    profile.options[2].onClick = handleLogout;
    return profile;
  }, [dashboardUserInfo, handleLogout]);

  const currentRole = getFacilityRoleById(
    dashboardUserInfo?.facility_role_id ?? 0
  );
  const isEligibleForNewUI = isRoleEligibleForNewUI(currentRole);

  return (
    <>
      <Wrapper>
        <AppBar position="fixed">
          <Toolbar>
            <Grid container direction="row">
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                xs={10}
              >
                <Grid item>
                  <NavLink to="/">
                    <OmcareLogo src={navLogo} alt="OmcareLogo" />
                  </NavLink>
                </Grid>
                {menuOptions.map((menu) => (
                  <NavBarMenuItem
                    menu={menu}
                    key={menu.title}
                    menuOptions={menuOptions}
                  />
                ))}
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                xs={2}
              >
                {isEligibleForNewUI && (
                  <Grid item>
                    <UIToggleSwitch />
                  </Grid>
                )}
                {profileMenu && (
                  <Grid item>
                    <NavBarMenuItem
                      menuOptions={menuOptions}
                      menu={profileMenu}
                      placement="bottom-end"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {isLoading && <LoadingSpinner />}
      </Wrapper>
      <Toolbar />
    </>
  );
}

export default NavBar;

interface NabBarMenuItemProps {
  menu: NavBarMenu;
  placement?: PopperPlacementType;
  menuOptions: NavBarMenu[];
}

const NavBarMenuItem = (props: NabBarMenuItemProps) => {
  const { menu, placement, menuOptions } = props;
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<any>(null);
  const [activeMenuItem, setActiveMenuItem] = useState<NavBarMenu | undefined>(
    undefined
  );

  const haveSubMenu = menu.options.length > 1;

  const handleMenuClick = () => {
    if (haveSubMenu) {
      setMenuOpen((prevOpen) => !prevOpen);
    } else {
      history.push(menu.path);
    }
  };
  const handleSubMenuClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    submenu: NavBarOptions
  ) => {
    if (submenu.onClick) {
      submenu.onClick();
    } else {
      history.push(submenu.path);
    }

    handleClose(event as unknown as MouseEvent);
  };
  const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const listener = history.listen((location) => {
      const activeUrl = location.pathname;
      const activeMenuItem = menuOptions.find((option) => {
        if (option.path) {
          return activeUrl.includes(option.path);
        }
        return !!option.options.find((subMenu) => {
          return activeUrl.includes(subMenu.path);
        });
      });
      setActiveMenuItem(activeMenuItem);
    });
    return () => {
      listener();
    };
  }, [history, menuOptions]);
  return (
    <Grid item>
      <CustomizedButton
        ref={menuAnchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup={haveSubMenu ? 'true' : 'false'}
        onClick={handleMenuClick}
        isCustomComponent={menu.isCustomComponent}
        activePage={menu.title === activeMenuItem?.title}
        endIcon={haveSubMenu ? <FontAwesomeIcon icon={faAngleDown} /> : null}
      >
        {menu.isCustomComponent ? menu.component : menu.title}
      </CustomizedButton>
      {haveSubMenu && (
        <Popper
          open={menuOpen}
          anchorEl={menuAnchorRef.current}
          role={undefined}
          transition
          placement={placement}
          disablePortal
          style={{ zIndex: 3 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <CustomPaper>
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                  <MenuList onKeyDown={(e) => handleListKeyDown(e)}>
                    {menu.options.map((submenu: NavBarOptions) => {
                      if (submenu.isCustomComponent) return submenu.component;
                      return (
                        <MenuItem
                          key={submenu.title}
                          onClick={(e) => handleSubMenuClick(e, submenu)}
                          tabIndex={0}
                        >
                          {submenu.title}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </CustomPaper>
            </Grow>
          )}
        </Popper>
      )}
    </Grid>
  );
};
