import React from 'react';
import { Box, Typography } from '@mui/material';
import { lightBlack, lightTeal } from '../../mainTheme';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2" mb={1} sx={{ color: '#9999A4' }}>
        <Typography component="span" color={lightBlack}>
          {currentStep}
        </Typography>
        /{totalSteps}
      </Typography>
      <Box display="flex" width="100%" gap={1}>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <Box
            key={index}
            flex="1 0 0"
            height="4px"
            bgcolor={currentStep > index ? lightTeal : '#F0FAF8'}
            borderRadius="2px"
          />
        ))}
      </Box>
    </Box>
  );
};

export default ProgressBar;
