import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import authReducer from './auth/reducer';
import customersReducer from './customers/reducer';
import hubOwnerReducer from './hubOwner/reducer';
import dashboardUserReducer from './dashboardUser/reducer';
import devicesReducer from './devices/reducer';
import facilityReducer from './facility/reducer';
import myAccountReducer from './myAccount/reducer';
import pharmacyReducer from './pharmacy/reducer';
import reportingReducer from './reporting/reducer';
import softwareReducer from './software/reducer';
import webSocketReducer from './webSocket/reducer';
import medicationsReducer from './medications/reducer';
import WebsocketMiddleware from '../middleware/websocket';
import { AuthStore } from './auth/authTypes';
import { CustomerStore } from './customers/customerTypes';
import { DashboardUserStore } from './dashboardUser/dashboardUserTypes';
import { DevicesStore } from './devices/deviceTypes';
import { FacilityStore } from './facility/facilityTypes';
import { MyAccountStore } from './myAccount/myAccountTypes';
import { ReportingStore } from './reporting/reportingTypes';
import { WebSocketStore } from './webSocket/webSocketTypes';
import { PharmacyStore } from './pharmacy/pharmacyTypes';
import { SoftwareStore } from './software/softwareTypes';
import { HubOwnerStore } from './hubOwner/types';
import { MedicationStore } from './medications/types';
import { UIToggleStore } from './uiToggle/uiToggleTypes';
import uiToggleReducer from './uiToggle/reducer';

export interface AppState {
  auth: AuthStore;
  customers: CustomerStore;
  dashboardUser: DashboardUserStore;
  devices: DevicesStore;
  facility: FacilityStore;
  myAccount: MyAccountStore;
  pharmacy: PharmacyStore;
  reporting: ReportingStore;
  software: SoftwareStore;
  webSocket: WebSocketStore;
  hubOwner: HubOwnerStore;
  medications: MedicationStore;
  uiToggle: UIToggleStore;
}

let rootReducer = combineReducers({
  auth: authReducer,
  customers: customersReducer,
  dashboardUser: dashboardUserReducer,
  devices: devicesReducer,
  facility: facilityReducer,
  myAccount: myAccountReducer,
  pharmacy: pharmacyReducer,
  reporting: reportingReducer,
  software: softwareReducer,
  webSocket: webSocketReducer,
  hubOwner: hubOwnerReducer,
  medications: medicationsReducer,
  uiToggle: uiToggleReducer,
});

const redux_middleware = [WebsocketMiddleware, thunk];

export const RootStore = createStore<AppState, any, any, any>(
  rootReducer,
  composeWithDevTools(applyMiddleware(...redux_middleware))
);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof RootStore.dispatch;
