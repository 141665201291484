import { faKey, faUser } from '@fortawesome/pro-light-svg-icons';
import { NavBarMenuObject } from '../store/GlobalTypes';

export const SCHEDULER_ACCOUNT_DETAILS_TABS = {
  ACCOUNT_DETAILS: { text: 'Account details', icon: null },
  DEVICE_DETAILS: { text: 'Ōmcare details', icon: null },
  CARE_TEAM: { text: 'Care team', icon: null },
  MEDICATION_SCHEDULE: { text: 'Medication schedule', icon: null },
};

export const ADMIN_ACCOUNT_DETAILS_TABS = {
  ACCOUNT_DETAILS: { text: 'Account details', icon: null },
  DEVICE_DETAILS: { text: 'Ōmcare details', icon: null },
  CARE_TEAM: { text: 'Care team', icon: null },
};

export const ACCOUNT_DETAILS_ONLY = {
  ACCOUNT_DETAILS: { text: 'Account details', icon: null },
};

export const MY_ACCOUT_TABS = {
  PERSONAL_INFO: { text: 'Personal info', icon: faUser },
  CHANGE_PASSWORD: { text: 'Change password', icon: faKey },
};

export const REPORTING_TABS = {
  DASHBOARD: { text: 'Dashboard', icon: null },
  ADHERENCE: { text: 'Aggregate adherence', icon: null },
};

export const ONE_YEAR_IN_MSECONDS = 365 * 24 * 3600 * 1000;

export const FACILITY_ROLE = {
  FACILITY_ADMIN: 1,
  FACILITY_SCHEDULER: 2,
  FACILITY_CAREGIVER: 3,
  FACILITY_PATIENT: 4,
  FACILITY_NURSE: 5,
};
export const CARETEAM_ROLE = {
  PROFESSIONAL_CAREGIVER: 1,
  FAMILY_ADMIN: 2,
  FAMILY_CAREGIVER: 3,
  FAMILY_CONTACT: 4,
  FACILITY_SCHEDULER: 5,
};

export const PHARMACY_ROLE = {
  TECHNICIAN: 1,
};

export const US_STATES = [
  { id: 'AL', name: 'Alabama' },
  { id: 'AK', name: 'Alaska' },
  { id: 'AZ', name: 'Arizona' },
  { id: 'AR', name: 'Arkansas' },
  { id: 'CA', name: 'California' },
  { id: 'CO', name: 'Colorado' },
  { id: 'CT', name: 'Connecticut' },
  { id: 'DE', name: 'Delaware' },
  { id: 'DC', name: 'District of Columbia' },
  { id: 'FL', name: 'Florida' },
  { id: 'GA', name: 'Georgia' },
  { id: 'HI', name: 'Hawaii' },
  { id: 'ID', name: 'Idaho' },
  { id: 'IL', name: 'Illinois' },
  { id: 'IN', name: 'Indiana' },
  { id: 'IA', name: 'Iowa' },
  { id: 'KS', name: 'Kansas' },
  { id: 'KY', name: 'Kentucky' },
  { id: 'LA', name: 'Louisiana' },
  { id: 'ME', name: 'Maine' },
  { id: 'MD', name: 'Maryland' },
  { id: 'MA', name: 'Massachusetts' },
  { id: 'MI', name: 'Michigan' },
  { id: 'MN', name: 'Minnesota' },
  { id: 'MS', name: 'Mississippi' },
  { id: 'MO', name: 'Missouri' },
  { id: 'MT', name: 'Montana' },
  { id: 'NE', name: 'Nebraska' },
  { id: 'NV', name: 'Nevada' },
  { id: 'NH', name: 'New Hampshire' },
  { id: 'NJ', name: 'New Jersey' },
  { id: 'NM', name: 'New Mexico' },
  { id: 'NY', name: 'New York' },
  { id: 'NC', name: 'North Carolina' },
  { id: 'ND', name: 'North Dakota' },
  { id: 'OH', name: 'Ohio' },
  { id: 'OK', name: 'Oklahoma' },
  { id: 'OR', name: 'Oregon' },
  { id: 'PA', name: 'Pennsylvania' },
  { id: 'RI', name: 'Rhode Island' },
  { id: 'SC', name: 'South Carolina' },
  { id: 'SD', name: 'South Dakota' },
  { id: 'TN', name: 'Tennessee' },
  { id: 'TX', name: 'Texas' },
  { id: 'UT', name: 'Utah' },
  { id: 'VT', name: 'Vermont' },
  { id: 'VA', name: 'Virginia' },
  { id: 'WA', name: 'Washington' },
  { id: 'WV', name: 'West Virginia' },
  { id: 'WI', name: 'Wisconsin' },
  { id: 'WY', name: 'Wyoming' },
];

export const REACT_ERROR_CODES = {
  INVALID_SYNC_CODE: 1001,
  INVALID_PARAM: 1002,
};

export const AUTH_MESSAGES = {
  LIMIT_EXCEEDED: 'Attempt limit exceeded',
  FAILED_TOKEN_VALIDATION: 'Failed to validate token',
  EMAIL_VERIFICATION_SUCCESS: 'Email verification successful',
  EMAIL_ALREADY_VERIFIED: 'Your email has already been previously verified',
  EMAIL_VERIFICATION_REQUIRED: 'EMAIL_VERIFICATION_REQUIRED',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA_REQUIRED: 'MFA_REQUIRED',
  PHONE_MFA_SETUP_REQUIRED: 'PHONE_MFA_SETUP_REQUIRED',
};

export const SOFTWARE_CHANNEL_ID = {
  STABLE: 1,
  BETA: 2,
};
export const NavBarMenus: NavBarMenuObject = {
  Users: {
    title: 'Users',
    path: '',
    options: [
      {
        title: 'Owners',
        path: '/owners',
      },
      {
        title: 'Caregivers',
        path: '/caregivers',
      },
    ],
  },
  Partners: {
    title: 'Partners',
    path: '',
    options: [
      {
        title: 'Facilities',
        path: '/facilities',
      },
      {
        title: 'Pharmacies',
        path: '/pharmacies',
      },
    ],
  },
  Devices: {
    title: 'Devices',
    path: '/devices',
    options: [],
  },
  Software: {
    title: 'Software',
    path: '',
    options: [
      {
        title: 'Versions',
        path: '/software',
      },
      {
        title: 'Deployments',
        path: '/softwareDeployment',
      },
    ],
  },

  Customers: {
    title: 'Customers',
    path: '/customers',
    options: [],
  },
  Reporting: {
    title: 'Reporting',
    path: '/reporting',
    options: [],
  },
  Operations: {
    title: 'Operations',
    path: '/ops',
    options: [],
  },
  MyFacility: {
    title: 'My Facility',
    path: '/facility',
    options: [],
  },
  Support: {
    title: 'Support',
    path: '/support',
    options: [],
  },
  Notifications: {
    title: 'Notifications',
    path: '/notifications',
    options: [],
  },
  Profile: {
    title: 'Profile',
    path: '',
    isCustomComponent: true,
    component: null,
    options: [
      {
        title: 'Profile Name',
        path: '/myAccount',
        isCustomComponent: true,
        component: null,
      },
      {
        title: 'My account',
        path: '/myAccount',
      },
      {
        title: 'Log out',
        path: '',
        onClick: null,
      },
    ],
  },
};

export const DEVICE_FAULT_CODES = {
  dispense_fault: 10, // device failed to dispense, needs maintenance
  update_fault: 20, // device failed to update, should resolve automatically
  adherence_sync_fault: 30, // medication is out of sync between omcare and the server, should resolve automatically
};

export const DATE_FORMAT = 'MM/DD/YYYY';

export const LONG_DATE_FORMAT = 'MMMM Do, YYYY';

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';

export const DATE_TIME_SECONDS_FORMAT = 'MM/DD/YYYY hh:mm:ss A';

export const careteamRoleOptions = [
  {
    label: 'Family admin',
    value: CARETEAM_ROLE.FAMILY_ADMIN,
  },
  {
    label: 'Family caregiver',
    value: CARETEAM_ROLE.FAMILY_CAREGIVER,
  },
  {
    label: 'Family contact',
    value: CARETEAM_ROLE.FAMILY_CONTACT,
  },
];

export const UNIFIED_FACILITY_ADMIN_ROLES = [
  FACILITY_ROLE.FACILITY_SCHEDULER,
  FACILITY_ROLE.FACILITY_ADMIN,
];

export const PRIVILEGE = {
  CUSTOMER_CARE: 2,
  ENGINEERING: 3,
};

export const AccountDelModalTypes = {
  ONLY_ADMIN_WARNING: 'OnlyAdminWarning',
  ASSIGN_ADMIN_MODAL: 'AssignAdminModal',
  INVITE_NEW_ADMIN_MODAL: 'InviteNewAdminModal',
  CONFIRM_DELETION_MODAL: 'ConfirmDeletionModal',
};

export const RELATIONSHIP_TO_OWNER = {
  FAMILY_ADMIN: 'family_admin',
  FAMILY_CAREGIVER: 'family_caregiver',
  FAMILY_CONTACT: 'family_contact',
  PROFESSIONAL_CAREGIVER: 'professional_caregiver',
  FACILITY_CAREGIVER: 'facility_caregiver',
  FACILITY_ADMIN: 'facility_admin',
} as const;

export type RelationshipToOwner =
  typeof RELATIONSHIP_TO_OWNER[keyof typeof RELATIONSHIP_TO_OWNER];
