import { Dispatch } from 'redux';
import { UIToggleActionTypes, ToggleUIActionType } from './uiToggleTypes';

export const toggleUI = (useNewUI: boolean): ToggleUIActionType => ({
  type: UIToggleActionTypes.TOGGLE_UI,
  payload: { useNewUI },
});

export const setToggleUI = (useNewUI: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(toggleUI(useNewUI));
  };
};
