import React from 'react';
import { Box, Typography } from '@mui/material';
import { Field } from 'formik';
import { TextInputFormField } from '../TextInputFormField';
import { lightBlack } from '../../../mainTheme';

const InviteNewAdminForm = () => {
  return (
    <>
      <Typography color={lightBlack} ml={4} mb={2}>
        Invite new admin
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Field
          label="First name"
          name="firstName"
          component={TextInputFormField}
        />
        <Field
          label="Email address"
          name="email"
          component={TextInputFormField}
        />
      </Box>
    </>
  );
};

export default InviteNewAdminForm;
