import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { ChangePasswordInterface } from '../../../store/auth/authTypes';
import NewModal from '../../Shared/NewModal';
import {
  IconAdornment,
  TextInputFormField,
} from '../../Shared/TextInputFormField';
import {
  faCircleCheck,
  faEye,
  faEyeSlash,
} from '@fortawesome/pro-light-svg-icons';
import PasswordStrengthBar from '../../Shared/PasswordStrengthBar';
import ReCaptchaVerifierV2 from '../../Shared/ReCaptchaVerifierV2/ReCaptchaVerifierV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customSuccess } from '../../../mainTheme';

const schema = yup.object({
  current_password: yup.string().required('Current Password is required'),
  new_password: yup
    .string()
    .min(8, 'Minimum 8 characters')
    .matches(/[a-z]/, 'Contains lowercase letter')
    .matches(/[A-Z]/, 'Contains uppercase letter')
    .matches(/\d/, 'Contains number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contains special character')
    .required('Password is required')
    .when('current_password', {
      is: (current_password: string) => !!current_password,
      then: yup
        .string()
        .notOneOf(
          [yup.ref('current_password')],
          'New password cannot match old password'
        ),
    }),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

interface ChangePasswordModalProps {
  onClose: () => void;
  onSubmit: (values: ChangePasswordInterface) => void;
  initialValues: ChangePasswordInterface;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  onClose,
  onSubmit,
  initialValues,
}) => {
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '';

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(!siteKey);

  const handleCaptchaVerification = (verified: boolean) => {
    setIsCaptchaVerified(verified);
  };

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <NewModal
      onClose={onClose}
      title="Change password"
      showCloseX
      maxWidth="xs"
    >
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="current_password"
              type={showCurrentPassword ? 'text' : 'password'}
              label="Current password"
              placeholder="Enter current password"
              component={TextInputFormField}
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconAdornment
                    onClick={handleClickShowCurrentPassword}
                    faIconProps={{
                      icon: showCurrentPassword ? faEyeSlash : faEye,
                    }}
                    width="20px"
                  />
                ),
              }}
            />
            <Field
              name="new_password"
              type={showPassword ? 'text' : 'password'}
              label="New password"
              placeholder="Enter new password"
              component={TextInputFormField}
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconAdornment
                    onClick={handleClickShowPassword}
                    faIconProps={{
                      icon: showPassword ? faEyeSlash : faEye,
                    }}
                    width="20px"
                  />
                ),
              }}
            />
            {values.new_password && (
              <PasswordStrengthBar password={values.new_password} />
            )}
            <Box display="flex" alignItems="center" mt={2}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={
                  schema.fields.new_password.isValidSync(values.new_password)
                    ? customSuccess
                    : 'grey'
                }
              />
              <Typography variant="caption" color={'grey'} ml={1}>
                Must include at least 1 letter, 1 number, 1 special character
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={values.new_password.length >= 8 ? customSuccess : 'grey'}
              />
              <Typography variant="caption" color={'grey'} ml={1}>
                Must be more than 8 characters in length
              </Typography>
            </Box>
            <Field
              name="confirm_password"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm password"
              placeholder="Confirm new password"
              component={TextInputFormField}
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconAdornment
                    onClick={handleClickShowConfirmPassword}
                    faIconProps={{
                      icon: showConfirmPassword ? faEyeSlash : faEye,
                    }}
                    width="20px"
                  />
                ),
              }}
            />
            {siteKey && (
              <ReCaptchaVerifierV2
                sitekey={siteKey}
                onVerify={handleCaptchaVerification}
              />
            )}
            <Box display="flex" justifyContent="space-between" gap={4} mt={3}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                disabled={!isCaptchaVerified}
              >
                Confirm
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </NewModal>
  );
};

export default ChangePasswordModal;
