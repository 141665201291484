import React from 'react';
import { Box, Typography } from '@mui/material';
import { customSuccess, lightBlack } from '../../mainTheme';

interface PasswordStrengthBarProps {
  password: string;
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({
  password,
}) => {
  const criteria = [
    { label: 'Lower case', isValid: /[a-z]/.test(password) },
    { label: 'Capital case', isValid: /[A-Z]/.test(password) },
    { label: 'Number', isValid: /\d/.test(password) },
    {
      label: 'Special character',
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    { label: '8 characters', isValid: password.length >= 8 },
  ];

  const strength = criteria.filter((criterion) => criterion.isValid).length;

  return (
    <Box px={1}>
      <Typography
        sx={{
          color: lightBlack,
          fontSize: '14px',
        }}
        mb={1}
      >
        Password strength
      </Typography>
      <Box display="flex" gap={1}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            data-testid="strength-bar"
            key={index}
            height="9px"
            flex="1 0 0"
            bgcolor={index < strength ? customSuccess : '#D9D9D9'}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PasswordStrengthBar;
