import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import NewModal from '../../Shared/NewModal';
import { ReactComponent as DeletedIcon } from '../../../images/icons/deleted_icon.svg';
import { dimGrey, lightBlack } from '../../../mainTheme';

interface UserDeletedModalProps {
  onClose: () => void;
  heading: string;
  modalTitle: string;
  modalContent?: boolean;
}

const UserDeletedModal: React.FC<UserDeletedModalProps> = ({
  onClose,
  heading,
  modalTitle,
  modalContent,
}) => {
  return (
    <NewModal maxWidth="xs" onClose={onClose} title={heading} showCloseX>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <DeletedIcon />
        <Typography variant="h6">{modalTitle}</Typography>
        {modalContent && (
          <Typography
            sx={{ color: dimGrey, textAlign: 'center' }}
            component="span"
          >
            Your account will be permanently deleted within{' '}
            <Typography component="span" fontWeight="bold" color={lightBlack}>
              72 hours.
            </Typography>
          </Typography>
        )}
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          OK
        </Button>
      </Box>
    </NewModal>
  );
};

export default UserDeletedModal;
