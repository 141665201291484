import React from 'react';
import { Box, Typography } from '@mui/material';
import { lightBlack, lightPurple } from '../../../mainTheme';

interface OverviewCardProps {
  title: string;
  count: string | number;
  description?: string;
  link?: string; // Optional link for redirection
}

const OverviewCard: React.FC<OverviewCardProps> = ({
  title,
  count,
  description,
  link,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      sx={{
        border: '1px solid #D4D4D9',
        borderRadius: '8px',
        padding: '16px',
        flexGrow: 1,
        cursor: link ? 'pointer' : 'default',
        backgroundColor: 'none',
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': link
          ? {
              backgroundColor: lightPurple,
              '& .overview-card-title, & .overview-card-count': {
                color: 'white',
              },
              '& .overview-card-description': {
                color: '#D6D6DC',
              },
            }
          : {}, // Apply hover styles only if link exists
      }}
      onClick={() => {
        if (link) window.location.href = link; // Redirect to the link if provided
      }}
    >
      <Typography
        className="overview-card-title"
        sx={{
          fontSize: '18px',
          color: lightBlack, // Default color
        }}
      >
        {title}
      </Typography>
      <Typography
        className="overview-card-count"
        sx={{
          fontSize: '22px',
          color: lightBlack, // Default color
        }}
      >
        {count}
      </Typography>
      {description && (
        <Typography
          className="overview-card-description"
          sx={{
            fontSize: '14px',
            color: '#9999A4', // Default color
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default OverviewCard;
