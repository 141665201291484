/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Drug } from '../models/Drug';
import type { LoadScheduleMatchItem } from '../models/LoadScheduleMatchItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DeviceService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve a JSON object of the owners customer information from the database
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerInfo(): CancelablePromise<{
    customer_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    phone2?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    gender_id?: number;
    date_of_birth?: string;
    age?: number;
    is_patient?: boolean;
    med_window_time_sec?: number;
    previlege_id?: number;
    email_verified_at?: string;
    facility_id?: number;
    facility_role_id?: number;
    pharmacy_name?: string;
    pharmacy_patient_id?: string;
    device_serial_number?: string;
    device_id?: string;
    device_synced_at?: string;
    device_software_version?: string;
    device_hardware_version?: string;
    device_time_zone?: string;
    device_active_fault_codes?: Array<string>;
    facility_name?: string;
    _global_permissions?: Array<'user.onboarding'>;
    synced_devices?: Array<{
      device_id?: number;
      assigned_customer_id?: number;
    }>;
    /**
     * If hub is "online" or "offline"
     */
    hub_connectivity_status?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/device/getCustomerInfo',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Retrieve a medication image
   * @param drugId
   * @returns any Successfully get an image
   * @throws ApiError
   */
  public getMedicationImage(drugId?: number): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/device/medication/{drug_id}/image',
      path: {
        drug_id: drugId,
      },
      errors: {
        400: `error`,
      },
    });
  }

  /**
   * Endpoint for a device to check if there is a software update available
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public getUpdateAvailable(requestBody?: {
    version?: string;
  }): CancelablePromise<{
    update_available?: boolean;
    version?: string;
    download_url?: string;
    image_type?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/software/version/check',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `Internal Error`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Post a new device fault for a device
   * @param requestBody
   * @returns any Successfully updated the fault
   * @throws ApiError
   */
  public addDeviceFault(requestBody?: {
    device_fault_code_id?: number;
    payload?: any;
    created_at?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/fault',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Clear an active device fault
   * @param requestBody
   * @returns any Successfully deleted the active fault
   * @throws ApiError
   */
  public clearDeviceFaults(requestBody?: {
    device_fault_code_id?: number;
    cleared_by_pin?: string | null;
    pin_used_at?: string | null;
    cleared_at?: string | null;
    auto_cleared_at?: string | null;
    created_at?: string | null;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/device/fault',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid Parameters`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        500: `INTERNAL_SERVER_ERROR`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Inform that server of a pin event.
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public insertPinEvent(requestBody?: {
    pin?: string;
    permission_id?: number;
    authorized?: boolean;
    created_at?: string;
    pin_cached_at?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/event/pin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        404: `NOT_FOUND`,
        503: `SERVICE_UNAVAILABLE`,
      },
    });
  }

  /**
   * Return Dose Object with desired admin time
   * @param batchId
   * @param startDate
   * @param endDate
   * @returns any Success
   * @throws ApiError
   */
  public getMedicationUpdates(
    batchId?: number,
    startDate?: string,
    endDate?: string
  ): CancelablePromise<
    Array<{
      id?: number;
      med_event_id?: string;
      roll_id?: string;
      batch_id?: number;
      bag_number?: number;
      desired_admin_time?: string;
      event_type_id?: number;
      national_drug_code?: string;
      roll_packaged?: boolean;
      caregiver_customer_id?: number;
    }>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/device/medication_doses/{batch_id}',
      path: {
        batch_id: batchId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        400: `Could not find batch id for given customer`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Retrieve user notes
   * @returns any Success
   * @throws ApiError
   */
  public getCustomerNote(): CancelablePromise<{
    id?: number;
    patient_customer_id?: number;
    title?: string;
    note?: string;
    modified_at?: string;
    modified_by?: number;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/device/note',
      errors: {
        401: `UnAuthorized`,
        403: `UnAuthorized to view note`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update time zone of device
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public saveDeviceTimeZone(requestBody?: {
    time_zone?: string;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/device/time_zone',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `BAD_REQUEST`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update the pin of the Owner
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public updateOwnerPin(requestBody?: {
    /**
     * The new 4 digit pin of the owner
     */
    pin?: number;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/device/pin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid PIN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update the device's hardware version
   * @param requestBody
   * @returns any Hardware version is already on latest
   * @throws ApiError
   */
  public updateDeviceHardwareVersion(requestBody?: {
    /**
     * The new hardware version
     */
    hw_version?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/device/hardware_version',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid hardware version`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Update the device's hardware and software version
   * @param requestBody
   * @returns any Hardware and software version are already the latest
   * @throws ApiError
   */
  public updateDeviceVersions(requestBody?: {
    /**
     * The current hardware version of OMCARE device
     */
    hw_version?: string;
    /**
     * The current software version of OMCARE device
     */
    sw_version?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/device/versions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Validate non-owner temporary pins
   * @param deviceId
   * @param requestBody
   * @returns any Success - valid pin
   * @throws ApiError
   */
  public validateNonOwnerPin(
    deviceId?: number,
    requestBody?: {
      /**
       * Temporary pin of non-owner
       */
      pin?: string;
    }
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/{device_id}/validate_pin',
      path: {
        device_id: deviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        462: `Expired Pin`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Unassign owner from device
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public unassign(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/unassign',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * OCR intelligence for pouch images
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public pouchRecognition(requestBody?: {
    images?: Array<Blob>;
  }): CancelablePromise<{
    result?: Array<{
      image?: string;
      ocrText?: {
        pouch_type: 'HEADER' | 'MEDICATION';
        name: {
          value?: string;
          confidence?: number;
        };
        batch_id?: {
          value?: string;
          confidence?: number;
        };
        pouch_number?: {
          value?: string;
          confidence?: number;
        };
        day_of_the_week?: {
          value?: string;
          confidence?: number;
        };
        date?: {
          value?: string;
          confidence?: number;
        };
        time?: {
          value?: string;
          confidence?: number;
        };
        medications?: Array<{
          value?: string;
          confidence?: number;
        }>;
      };
    }>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/pouch-recognition',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * OCR intelligence for pouch images (Enhanced)
   * @param skipMedicationMatching Flag to determine if to skip medication matching
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public aggregatePouchRecognition(
    skipMedicationMatching?: boolean,
    requestBody?: {
      images?: Array<Blob>;
    }
  ): CancelablePromise<{
    pouch_type: 'HEADER' | 'MEDICATION' | 'FOOTER' | 'UNKNOWN';
    med_event_id?: string;
    dose_ids?: Array<number>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/pouch-recognition/aggregate',
      query: {
        skip_medication_matching: skipMedicationMatching,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Loading Medication Schedule with OCR Intelligence
   * @param testMode Flag to determine if running in testing mode
   * @param skipMedicationMatching Flag to determine if to skip medication matching
   * @param startDate Start date for medication schedule
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public loadingPouchRecognition(
    testMode?: boolean,
    skipMedicationMatching?: boolean,
    startDate?: any,
    requestBody?: {
      images?: Array<Blob>;
    }
  ): CancelablePromise<{
    is_new?: boolean;
    drugs?: Array<Drug>;
    matching_schedule?: Array<LoadScheduleMatchItem>;
    pouch_information?: {
      pouch_type: 'HEADER' | 'MEDICATION' | 'FOOTER' | 'UNKNOWN';
      med_event_id?: string;
      dose_ids?: Array<number>;
    };
    failure_reason?:
      | 'FAILED_TO_RECOGNIZE_POUCH_INFORMATION'
      | 'NO_MEDICATION_SCHEDULE_MATCH';
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/pouch-recognition/loading',
      query: {
        test_mode: testMode,
        skip_medication_matching: skipMedicationMatching,
        start_date: startDate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Dispensing Medication Pouch with OCR Intelligence
   * @param skipMedicationMatching Flag to determine if to skip medication matching
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public dispensingPouchRecognition(
    skipMedicationMatching?: boolean,
    requestBody?: {
      images?: Array<Blob>;
    }
  ): CancelablePromise<{
    pouch_information?: {
      pouch_type: 'HEADER' | 'MEDICATION' | 'FOOTER' | 'UNKNOWN';
      med_event_id?: string;
      dose_ids?: Array<number>;
    };
    failure_reason?:
      | 'FAILED_TO_RECOGNIZE_POUCH_INFORMATION'
      | 'NO_MED_EVENT_ID_MATCH'
      | 'NO_DOSE_IDS_MATCH';
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/device/pouch-recognition/dispensing',
      query: {
        skip_medication_matching: skipMedicationMatching,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }

  /**
   * Get a presigned URL from S3 bucket for posting file uploads
   * @param requestBody
   * @returns any SUCCESSFUL
   * @throws ApiError
   */
  public getS3PresignedPostUrl(requestBody?: {
    /**
     * the HTTP content-type value for the media type of the file(s) to be uploaded to S3
     */
    content_type?: string;
    /**
     * the desired S3 object key prefix for file(s) being uploaded with presigned URL
     */
    object_key_prefix?: string;
  }): CancelablePromise<{
    /**
     * base POST url for request to upload object(s) to S3 bucket
     */
    url?: string;
    /**
     * S3 object key prefix for file(s) being uploaded with presigned URL
     */
    objectKeyPrefix?: string;
    /**
     * Key-value pairs for the contents of the Form-Data body of the POST upload request(s)
     */
    fields?: {
      /**
       * the HTTP content-type value for the media type of the file(s) to be uploaded to S3
       */
      'Content-Type'?: string;
      /**
       * S3 bucket name to be uploaded to
       */
      bucket?: string;
      /**
       * AWS automatic generated field - The hash algorithm that you used to create the request signature
       */
      'X-Amz-Algorithm'?: string;
      /**
       * AWS automatic generated field - The credential scope value
       */
      'X-Amz-Credential'?: string;
      /**
       * AWS automatic generated field - The date that is used to create the signature
       */
      'X-Amz-Date'?: string;
      /**
       * AWS automatic generated field - The temporary security token that was obtained through a call to AWS Security Token Service
       */
      'X-Amz-Security-Token'?: string;
      /**
       * AWS automatic generated field - Fixed policy code for S3 object upload
       */
      Policy?: string;
      /**
       * AWS automatic generated field - Specifies the hex-encoded signature that was calculated from the string to sign and the derived signing key
       */
      'X-Amz-Signature'?: string;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/device/s3/presigned-post',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `INVALID PARAM`,
        401: `UNAUTHORIZED`,
        403: `FORBIDDEN`,
        500: `INTERNAL_SERVER_ERROR`,
      },
    });
  }
}
