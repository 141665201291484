import React from 'react';
import { Box, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';

interface ActionCellProps {
  handleNavigation: () => void;
}

const ActionCell: React.FC<ActionCellProps> = ({ handleNavigation }) => {
  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <IconButton size="small" onClick={handleNavigation}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ marginRight: 16 }}
          size="sm"
        />
      </IconButton>
    </Box>
  );
};

export default ActionCell;
