import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1 } from '../../mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsFilter,
  faPenToSquare,
  faTimes,
  faCircleUser,
} from '@fortawesome/pro-light-svg-icons';
import NewModal from '../Shared/NewModal';
import { faCircleCheck, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import DataGridTable from '../Shared/DataGridTable/DataGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import HubsFilterForm from './components/HubsFilterForm';
import { getFacilityDevices } from '../../store/facility/actionCreators';
import { isNull } from 'lodash';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import StatusChip from '../Shared/StatusChip';
import { FacilityDevice } from '../../store/facility/facilityTypes';

export enum Status {
  Online = 'Online',
  Offline = 'Offline',
}

const ActionCell = ({ deviceId }: { deviceId: number }) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push(`/v2/hubs/${deviceId}`);
  };

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <IconButton size="small" onClick={handleNavigation}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ marginRight: 16 }}
          size="sm"
        />
      </IconButton>
    </Box>
  );
};

const stateSelector = (state: RootState) => {
  const { facility, dashboardUser } = state;
  const { facilityDevices, isLoading: isFacilityDeviceLoading } = facility;
  const { dashboardUserInfo, isLoading } = dashboardUser;
  return {
    facilityDevices,
    dashboardUserInfo,
    isLoading,
    isFacilityDeviceLoading,
  };
};

const columns = [
  { field: 'hub_serial_number', headerName: 'Hub serial number', flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <StatusChip
          icon={params.value.icon}
          iconColor={params.value.color}
          labelText={params.value.text}
          conditionalValue={params.value.text === Status.Online ? true : false}
        />
      </Box>
    ),
  },
  {
    field: 'assigned',
    headerName: 'Assigned',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <StatusChip
          icon={params.value.icon}
          iconColor={params.value.color}
          labelText={params.value.text}
          conditionalValue={params.value.text !== 'Unassigned' ? true : false}
          backgroundColor=" var(--Background-B2---Container, #F6F6F9)"
        />
      </Box>
    ),
  },
  { field: 'facility', headerName: 'Facility', flex: 1 },
  {
    field: 'action',
    headerName: 'Action',
    renderCell: (params: any) => <ActionCell deviceId={params.row.id} />,
  },
];

const HubIndex = () => {
  const dispatch = useDispatch();

  const {
    facilityDevices,
    dashboardUserInfo,
    isLoading: isDashboardUserLoading,
    isFacilityDeviceLoading,
  } = useSelector<RootState, ReturnType<typeof stateSelector>>(stateSelector);

  const facilityId = dashboardUserInfo ? dashboardUserInfo.facility_id : null;

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  useEffect(() => {
    if (
      facilityId &&
      (!facilityDevices ||
        (facilityDevices && facilityDevices.facility_id !== facilityId))
    ) {
      dispatch(getFacilityDevices(facilityId));
    }
  }, [facilityId, dispatch, facilityDevices]);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    owner_name: '',
    hub_status: '',
    facility: '',
  });

  const rows = facilityDevices?.devices.map((device: FacilityDevice) => {
    const isOnline = device.device_status === Status.Online.toLowerCase();
    const hasOwner = device?.owner?.first_name && device?.owner?.last_name;

    return {
      id: device.id,
      hub_serial_number: device?.serial_number,
      status: {
        icon: isOnline ? faCircleCheck : faTimes,
        color: isOnline ? '#42AF60' : 'red',
        text: isOnline ? Status.Online : Status.Offline,
      },
      assigned: {
        icon: hasOwner ? faCircleUser : faWifiSlash,
        color: hasOwner ? '#585863' : '#DB5543',
        text: hasOwner
          ? `${device?.owner?.first_name} ${device?.owner?.last_name}`
          : 'Unassigned',
      },
      facility: device.facility_name || 'No data',
      action: faBarsFilter,
    };
  });

  const formRef =
    useRef<
      FormikProps<{ owner_name: string; hub_status: string; facility: string }>
    >(null);

  const handleContinue = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const onSubmitFilterForm = (values: any) => {
    if (formRef.current) {
      setFilters(values);
      setIsFilterModalOpen(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter data based on search term

  const filteredData = rows?.filter((row) => {
    const matchesSearchTerm =
      row.assigned.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.status.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.facility.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.hub_serial_number.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilters =
      (filters.owner_name === '' ||
        row.assigned.text
          .toLowerCase()
          .includes(filters.owner_name.toLowerCase())) &&
      (filters.facility === '' ||
        row.facility.toLowerCase().includes(filters.facility.toLowerCase())) &&
      (filters.hub_status === '' || row.status.text === filters.hub_status);

    return matchesSearchTerm && matchesFilters;
  });

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1}>
      <Box flexGrow={1} p={4}>
        <Box pb={4}>
          <Typography>Hubs</Typography>
        </Box>
        <Typography variant="caption">Search</Typography>
        <Box display="flex" gap={2}>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            variant="outlined"
          />
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faBarsFilter} />}
            onClick={() => setIsFilterModalOpen(true)}
          />
        </Box>
        <DataGridTable
          rows={filteredData || []}
          columns={columns}
          loading={isFacilityDeviceLoading || isDashboardUserLoading}
        />
      </Box>
      {isFilterModalOpen ? (
        <NewModal
          showCloseX
          title="Filter"
          onClose={() => {
            setIsFilterModalOpen(false);
            setFilters({
              owner_name: '',
              hub_status: '',
              facility: '',
            });
            if (formRef.current) {
              formRef.current.resetForm();
            }
          }}
          actions={{
            primaryAction: { onClick: handleContinue },
          }}
        >
          <HubsFilterForm
            formRef={formRef}
            onSubmit={onSubmitFilterForm}
            initialValues={filters}
          />
        </NewModal>
      ) : null}
    </Box>
  );
};

export default HubIndex;
