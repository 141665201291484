import { faDiamondExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  DEVICE_FAULT_CODES,
  PRIVILEGE,
  UNIFIED_FACILITY_ADMIN_ROLES,
} from '../../../shared/constants';
import { Owner } from '../../../store/customers/customerTypes';
import { DashboardUser } from '../../../store/dashboardUser/dashboardUserTypes';
import DataRowItem from '../../Shared/DataRowItem';
import OnlineStatus from '../../Shared/OnlineStatus';

interface DeviceDetailsProps {
  customer: Owner;
  dashboardUserInfo: DashboardUser | null;
  title: string;
  setShowSyncDeviceModal(arg0: boolean): void;
  setUnassignConfirmationVisibility(arg0: boolean): void;
}

const DeviceDetails = (props: DeviceDetailsProps) => {
  const {
    customer,
    dashboardUserInfo,
    title,
    setShowSyncDeviceModal,
    setUnassignConfirmationVisibility,
  } = props;
  return (
    <div>
      <Typography variant={'h1'}>{title}</Typography>
      <Grid container alignItems="flex-start" spacing={2}>
        {customer.device_id ? (
          <>
            <DataRowItem
              title="Ōmcare device model number"
              titleSize={3}
              data={customer.device_hardware_version}
            />
            <DataRowItem
              title={'Ōmcare device serial number'}
              titleSize={3}
              data={customer.device_serial_number}
            />
            <DataRowItem
              title="Software version"
              data={customer.device_software_version}
              titleSize={3}
            />
            <DataRowItem
              title="Device mode(s)"
              data={
                customer.device_id
                  ? (customer.device_active_fault_codes || []).includes(
                      DEVICE_FAULT_CODES.dispense_fault
                    )
                    ? 'Maintenance'
                    : 'Ready'
                  : null
              }
              titleSize={3}
            />
            <DataRowItem
              title="Current status"
              titleSize={3}
              render={() => {
                return customer.device_id ? (
                  <OnlineStatus isOnline={customer.is_online} />
                ) : (
                  '-'
                );
              }}
            />
            <DataRowItem
              render={() => {
                return (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setUnassignConfirmationVisibility(true)}
                  >
                    Unassign device
                  </Button>
                );
              }}
            />
            <DataRowItem
              render={() => {
                return (
                  <div style={{ color: 'rgb(204, 93, 75)' }}>
                    <FontAwesomeIcon
                      icon={faDiamondExclamation}
                      style={{ marginRight: '5px' }}
                    />
                    Unassigning an Ōmcare Home Health Hub from an owner will
                    erase all data on that device.
                  </div>
                );
              }}
            />
          </>
        ) : (
          <>
            {dashboardUserInfo &&
              (UNIFIED_FACILITY_ADMIN_ROLES.includes(
                dashboardUserInfo.facility_role_id as number
              ) ||
                dashboardUserInfo.privilege_id === PRIVILEGE.CUSTOMER_CARE) && (
                <DataRowItem
                  render={() => {
                    return (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowSyncDeviceModal(true)}
                      >
                        Assign device
                      </Button>
                    );
                  }}
                />
              )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default DeviceDetails;
