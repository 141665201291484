import React from 'react';

interface CareGiverIconProps {
  color?: string;
  size?: number;
  [key: string]: any;
}

const CareGiverIcon: React.FC<CareGiverIconProps> = ({
  color,
  size = 26,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        id="Vector"
        d="M12 1C14.21 1 16 2.79 16 5C16 7.21 14.21 9 12 9C9.79 9 8 7.21 8 5C8 2.79 9.79 1 12 1Z"
        fill={color}
      />
      <path
        id="Vector_2"
        d="M18.7563 11.1929C20.4146 12.781 20.4146 15.358 18.7563 16.9471L12.7506 22.7024C12.3365 23.0992 11.6635 23.0992 11.2494 22.7024L5.24371 16.9471C3.58543 15.358 3.58543 12.781 5.24371 11.1919C6.902 9.60272 9.59114 9.60272 11.2494 11.1919L11.9989 11.9111L12.7506 11.1919C14.4089 9.60272 17.098 9.60373 18.7563 11.1929Z"
        fill={color}
      />
    </svg>
  );
};

export default CareGiverIcon;
