import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { FormikValues } from 'formik';
import React, { ReactNode } from 'react';

interface ModalProps {
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  onClose?(): void;
  title?: string;
  showCloseX?: boolean;
  children: ReactNode;
  actions?: {
    primaryAction?: {
      text?: string;
      onClick(arg?: FormikValues): void;
      disabled?: boolean;
    };
    secondaryAction?: {
      text?: string;
      onClick(arg?: FormikValues): void;
      disabled?: boolean;
      varient?: 'outlined' | 'contained' | 'text';
    };
  };
  fullScreen?: boolean;
  noScoll?: boolean;
  backdropBlur?: boolean;
  isLoading?: boolean;
}

function NewModal(props: ModalProps) {
  const {
    maxWidth = 'sm',
    onClose,
    title,
    showCloseX,
    children,
    actions = null,
    fullScreen = false,
    noScoll = false,
    backdropBlur = false,
    isLoading = false,
  } = props;

  return (
    <Dialog
      open
      fullWidth
      maxWidth={maxWidth}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          if (typeof onClose === 'function') {
            onClose();
          }
        }
      }}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
      sx={{
        backdropFilter: backdropBlur ? 'blur(5px)' : 'none',
      }}
    >
      {title || showCloseX ? (
        <DialogTitle
          id="dialog-title"
          sx={{ fontSize: '18px', fontWeight: 500, padding: 0 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            px={2}
          >
            <Typography> {title} </Typography>
            {showCloseX && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                color="primary"
                sx={{ marginRight: '4px' }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </IconButton>
            )}
          </Box>

          <Divider sx={{ width: '100%' }} />
        </DialogTitle>
      ) : null}
      <DialogContent
        tabIndex={0}
        sx={{
          overflowY: noScoll ? 'hidden' : 'auto',
          paddingTop: '20px !Important',
        }}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            gap={3}
            px={2}
          >
            <Button
              variant={actions.secondaryAction?.varient ?? 'outlined'}
              color="secondary"
              fullWidth
              onClick={actions.secondaryAction?.onClick ?? onClose}
              // disabled={actions.secondaryAction?.disabled}
            >
              {actions.secondaryAction?.text ?? 'Cancel'}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={actions.primaryAction?.onClick}
              disabled={isLoading}
            >
              {actions.primaryAction?.text ?? 'Confirm'}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default NewModal;
