import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import { RootState } from '../../../store';
import { AUTH_MESSAGES } from '../../../shared/constants';
import { ReactComponent as ResetPasswordIcon } from '../../../images/icons/reset_password_icon.svg';
import {
  requestPhoneOTP,
  validatePhoneOTP,
  completePhoneMFASetup,
} from '../../../store/auth/actionCreators';

interface MfaVerificationProps {
  verifyMfaCode: (mfaCode: string) => void;
  resendCode: () => void;
  mfaMessage: string;
  username: string;
  sessionToken: string;
  phoneNumber?: string; // last 4 digits of registered phone number for MFA voice calls
}

const MfaVerification: React.FC<MfaVerificationProps> = ({
  verifyMfaCode,
  resendCode,
  mfaMessage,
  username,
  sessionToken,
  phoneNumber,
}) => {
  const phone_mfa = phoneNumber || '????';
  const dispatch = useDispatch();
  const { otpValidation } = useSelector((state: RootState) => state.auth);

  const [mfaCode, setMfaCode] = useState('');
  const [setupStep, setSetupStep] = useState(1);
  const [phoneInput, setPhoneInput] = useState('');
  const [otpCode, setOtpCode] = useState('');

  const handlePhoneInputChange = (value: string) => {
    if (/^\d{0,10}$/.test(value)) {
      setPhoneInput(value);
    }
  };

  const handleRequestPhoneOTP = () => {
    if (phoneInput) {
      dispatch(requestPhoneOTP(`+1${phoneInput}`, sessionToken));
      setSetupStep(2);
    }
  };

  const handleValidatePhoneOTP = () => {
    if (otpCode) {
      dispatch(validatePhoneOTP(phoneInput, otpCode, sessionToken));
    }
  };

  const handleCompleteMFASetup = () => {
    if (mfaCode) {
      dispatch(
        completePhoneMFASetup(
          username,
          mfaCode,
          sessionToken,
          `+1${phoneInput}`
        )
      );
    }
  };

  useEffect(() => {
    if (
      otpValidation &&
      otpValidation.valid &&
      otpValidation.phoneNumber === phoneInput
    ) {
      setSetupStep(3);
    }
  }, [otpValidation, phoneInput]);

  return (
    <Box mt={4} display="flex" flexDirection="column" gap={2}>
      <ResetPasswordIcon />
      <Typography>Multi-Factor Authentication</Typography>
      {mfaMessage === AUTH_MESSAGES.MFA_REQUIRED && (
        <>
          <Typography variant="subtitle2">
            A voice call with a verification code has been sent to your phone
            number ending in {phone_mfa}. Enter the code to continue.
          </Typography>
          <TextField
            label=""
            placeholder="Enter verification code"
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => verifyMfaCode(mfaCode)}
          >
            Verify
          </Button>
          <Button variant="text" color="secondary" onClick={resendCode}>
            Resend code
          </Button>
        </>
      )}

      {mfaMessage === AUTH_MESSAGES.PHONE_MFA_SETUP_REQUIRED && (
        <>
          {setupStep === 1 && (
            <>
              <Typography variant="subtitle2">
                To continue, you must enable multi-factor authentication by
                voice calls. To begin, enter a valid phone number below to
                receive a voice call with a confirmation code.
              </Typography>
              <Box display="flex" gap={1} alignItems="center">
                <Select value="US" disabled>
                  <MenuItem value="US">🇺🇸 +1</MenuItem>
                </Select>
                <TextField
                  placeholder="Enter phone number"
                  value={phoneInput}
                  onChange={(e) => handlePhoneInputChange(e.target.value)}
                  fullWidth
                />
              </Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleRequestPhoneOTP}
              >
                Call to confirm
              </Button>
            </>
          )}

          {setupStep === 2 && (
            <>
              <Typography variant="subtitle2">
                Enter the OTP confirmation code that you received on the voice
                call to <i>+1{phoneInput}</i>.
              </Typography>
              <TextField
                placeholder="Enter voice call OTP code"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleValidatePhoneOTP}
              >
                Confirm
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setSetupStep(1)}
              >
                Re-enter number
              </Button>
            </>
          )}

          {setupStep === 3 && (
            <>
              <Typography variant="subtitle2">
                A verification code was also sent to your registered email.
                Enter the code below to complete your multi-factor
                authentication phone setup.
              </Typography>
              <TextField
                placeholder="Enter email verification code"
                value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCompleteMFASetup}
              >
                Complete setup
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default MfaVerification;
