import React from 'react';
import { Chip, Typography } from '@mui/material';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightBlack } from '../../../../mainTheme';

interface PasswordCriteriaProps {
  label: string;
  isValid: boolean;
}

const PasswordCriteria = ({ label, isValid }: PasswordCriteriaProps) => {
  return (
    <Chip
      label={
        <Typography sx={{ color: lightBlack }} variant="caption">
          {label}
        </Typography>
      }
      icon={
        <FontAwesomeIcon
          icon={isValid ? faCheck : faTimes}
          style={{ color: isValid ? 'green' : 'red' }}
        />
      }
      sx={{
        backgroundColor: isValid
          ? 'var(--Semantics-S2---Success-Bg, #ECF8F0)'
          : `var(--Semantics-S11---Error-Bg, #FCF0EE)`,
        color: 'white',
        '& .MuiChip-icon': {
          color: 'white',
        },
      }}
    />
  );
};

export default PasswordCriteria;
