import React, { useMemo } from 'react';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { Link } from 'react-router-dom';

interface SidebarMenuItemProps {
  icon: React.ReactElement<SvgIconProps>;
  text: string;
  to: string;
  open: boolean;
  selected: boolean;
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  icon,
  text,
  to,
  open,
  selected,
}) => {
  const clonedIcon = useMemo(() => {
    return React.isValidElement(icon)
      ? React.cloneElement(icon, {
          style: { fill: selected ? '#6BCABA' : '#9999A4' },
        })
      : icon;
  }, [icon, selected]);

  return (
    <ListItem
      button
      component={Link}
      to={to}
      sx={{
        borderRadius: '10px',
        border: selected ? '1px solid var(--color-gray-medium, #D4D4D4)' : null,
        backgroundColor: selected ? 'rgba(0, 0, 0, 0.08)' : null,
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
      }}
    >
      <Box display="flex">
        <Box display="flex" alignItems="center" minWidth={40}>
          <ListItemIcon
            sx={{
              color: selected ? '#6BCABA' : '#9999A4',
              minWidth: 'auto',
              marginRight: '8px',
            }}
          >
            {clonedIcon}
          </ListItemIcon>
        </Box>
        <Box>
          {open && (
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: selected ? 'bold' : 'normal' }}>
                  {text}
                </Typography>
              }
              sx={{
                fontWeight: 'bold',
              }}
            />
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

export default SidebarMenuItem;
