import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import navLogo from '../../../images/omcare_side_nav_logo.png';

interface SidebarHeaderProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

const OmcareLogo = styled('img')({
  height: '30px',
});

const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  open,
  handleDrawerToggle,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: 2,
      }}
    >
      <Typography variant="h6" noWrap>
        {open ? (
          <NavLink to="/">
            <OmcareLogo src={navLogo} alt="OmcareLogo" />
          </NavLink>
        ) : (
          'Om'
        )}
      </Typography>
      <IconButton
        onClick={handleDrawerToggle}
        sx={{ ml: 'auto', display: 'none' }}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
};

export default SidebarHeader;
