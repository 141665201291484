import React from 'react';
import Topbar from '../../components/Shared/Topbar/Topbar';
import Sidebar from '../Sidebar/Sidebar';
import { Box } from '@mui/material';
import { lightBackground1 } from '../../mainTheme';

const UILayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Topbar />
      <Box
        display="flex"
        flex="1"
        marginTop="72px"
        overflow="hidden"
        height="100%"
      >
        <Sidebar />
        <Box
          display="flex "
          flexDirection="column"
          flex="1"
          width="100%"
          overflow="auto"
          bgcolor={lightBackground1}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default UILayout;
