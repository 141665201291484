import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import NewModal from '../../Shared/NewModal';
import { ReactComponent as WelcomeBackIcon } from '../../../images/icons/welcome_back_icon.svg';

interface PasswordChangeSuccessModalProps {
  onClose: () => void;
}

const PasswordChangeSuccessModal: React.FC<PasswordChangeSuccessModalProps> = ({
  onClose,
}) => {
  return (
    <NewModal
      maxWidth="xs"
      onClose={onClose}
      title="Change password"
      showCloseX
    >
      <WelcomeBackIcon />
      <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
        <Typography variant="h6">Password successfully updated</Typography>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </NewModal>
  );
};

export default PasswordChangeSuccessModal;
