export interface UIToggleStore {
  useNewUI: boolean;
}

export enum UIToggleActionTypes {
  TOGGLE_UI = 'TOGGLE_UI',
}

export type ToggleUIActionType = {
  type: UIToggleActionTypes.TOGGLE_UI;
  payload: { useNewUI: boolean };
};

export type UIToggleStoreAction = ToggleUIActionType;
