/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AuthService } from './services/AuthService';
import { CertificatesService } from './services/CertificatesService';
import { CustomerService } from './services/CustomerService';
import { CustomersService } from './services/CustomersService';
import { DefaultService } from './services/DefaultService';
import { DeviceService } from './services/DeviceService';
import { DevicesService } from './services/DevicesService';
import { EngService } from './services/EngService';
import { EventsService } from './services/EventsService';
import { FacilityService } from './services/FacilityService';
import { FactoryService } from './services/FactoryService';
import { MedicationsService } from './services/MedicationsService';
import { OwnersService } from './services/OwnersService';
import { PharmaciesService } from './services/PharmaciesService';
import { PharmacyService } from './services/PharmacyService';
import { PharmacyTechService } from './services/PharmacyTechService';
import { RegisterService } from './services/RegisterService';
import { ReportingService } from './services/ReportingService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class OmcareRestClient {
  public readonly auth: AuthService;
  public readonly certificates: CertificatesService;
  public readonly customer: CustomerService;
  public readonly customers: CustomersService;
  public readonly default: DefaultService;
  public readonly device: DeviceService;
  public readonly devices: DevicesService;
  public readonly eng: EngService;
  public readonly events: EventsService;
  public readonly facility: FacilityService;
  public readonly factory: FactoryService;
  public readonly medications: MedicationsService;
  public readonly owners: OwnersService;
  public readonly pharmacies: PharmaciesService;
  public readonly pharmacy: PharmacyService;
  public readonly pharmacyTech: PharmacyTechService;
  public readonly register: RegisterService;
  public readonly reporting: ReportingService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'https://rest.internal.omcare.com:5001',
      VERSION: config?.VERSION ?? '0.1.0-alpha',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.auth = new AuthService(this.request);
    this.certificates = new CertificatesService(this.request);
    this.customer = new CustomerService(this.request);
    this.customers = new CustomersService(this.request);
    this.default = new DefaultService(this.request);
    this.device = new DeviceService(this.request);
    this.devices = new DevicesService(this.request);
    this.eng = new EngService(this.request);
    this.events = new EventsService(this.request);
    this.facility = new FacilityService(this.request);
    this.factory = new FactoryService(this.request);
    this.medications = new MedicationsService(this.request);
    this.owners = new OwnersService(this.request);
    this.pharmacies = new PharmaciesService(this.request);
    this.pharmacy = new PharmacyService(this.request);
    this.pharmacyTech = new PharmacyTechService(this.request);
    this.register = new RegisterService(this.request);
    this.reporting = new ReportingService(this.request);
  }
}
