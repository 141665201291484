import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import MaskedInput from '../../Shared/MaskedInput';
import SelectFormField from '../../Shared/SelectFormField';
import { FACILITY_ROLE, US_STATES } from '../../../shared/constants';
import { Box } from '@mui/material';
import * as yup from 'yup';

const schema = yup.object({
  first_name: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'First name must contain only alphabetical characters'
    )
    .required('First name is required'),
  last_name: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'Last name must contain only alphabetical characters'
    )
    .required('Last name is required'),
  phone: yup.string().required('This field is required'),
  username: yup
    .string()
    .label('Email')
    .email('Please enter a valid email address')
    .required('This field is required'),
  facility_role_id: yup.string().required('This field is required'),

  address1: yup.string().required('This field is required'),

  city: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  zipcode: yup
    .number()
    .typeError('Invalid zip code')
    .required('This field is required')
    .max(99999, 'Invalid zip code')
    .min(10000, 'Invalid zip code'),
});

export interface NewCaregiverFormValues {
  first_name: string;
  last_name: string;
  phone: string;
  username: string;
  address1: string;
  city?: string;
  state?: string;
  zipcode?: number | null;
  role?: string;
}

interface AddNewCaregiverProps {
  formRef: React.RefObject<FormikProps<NewCaregiverFormValues>>;
  onSubmit: (values: any) => void;
}

const AddNewCaregiver: React.FC<AddNewCaregiverProps> = ({
  formRef,
  onSubmit,
}) => {
  const roleData = useMemo(() => {
    return [
      { label: 'Facility admin', value: FACILITY_ROLE.FACILITY_ADMIN },
      { label: 'Scheduler', value: FACILITY_ROLE.FACILITY_SCHEDULER },
      {
        label: 'Caregiver',
        value: FACILITY_ROLE.FACILITY_CAREGIVER,
      },
    ];
  }, []);

  const states_list = useMemo(() => {
    if (US_STATES.length < 0) return [];
    return US_STATES.map((item) => {
      return { label: item.name, value: item.id };
    });
  }, []);

  return (
    <Formik
      innerRef={formRef}
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={
        {
          first_name: '',
          last_name: '',
          phone: '',
          facility_role_id: '',
          username: '',
          address1: '',
          city: '',
          state: '',
          zipcode: null,
        } as NewCaregiverFormValues
      }
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} data-testid="add-new-care-giver-form">
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <Field
                label="First name"
                name="first_name"
                placeholder="First name"
                component={TextInputFormField}
              />
              <Field
                label="Last name"
                name="last_name"
                placeholder="Last name"
                component={TextInputFormField}
              />
            </Box>
            <Field
              label="Email address"
              name="username"
              placeholder="Enter an email address"
              component={TextInputFormField}
            />
            <Box display="flex" gap={2}>
              <Field
                label="Phone number"
                name="phone"
                format="(###) ###-####"
                placeholder="(123)-456-7890"
                component={MaskedInput}
              />
              <Field
                label="Role"
                name="facility_role_id"
                placeholder="Role"
                component={SelectFormField}
                options={roleData}
                useNewStyles
              />
            </Box>
            <Field
              label="Address"
              name="address1"
              placeholder="1234 Main Street"
              component={TextInputFormField}
            />
            <Field
              label="City"
              name="city"
              placeholder="City"
              component={TextInputFormField}
            />

            <Box display="flex" gap={2}>
              <Field
                label="State"
                name="state"
                placeholder="State"
                component={SelectFormField}
                options={states_list}
                useNewStyles
              />
              <Field
                label="Zip code"
                name="zipcode"
                placeholder="Zip code"
                component={TextInputFormField}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewCaregiver;
